/* eslint-disable react/no-unescaped-entities */
import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'
import withProps from 'recompose/withProps'
import keynumbersIcon from 'src/static/report_keynumbers.svg'
import marketIcon from 'src/static/report_market.svg'
import companyIcon from 'src/static/report_company.svg'
import foundedIcon from 'src/static/icFounded.png'
import eployeesGroupIcon from 'src/static/icEployeesGroup.png'
import fundingIcon from 'src/static/icFunding.png'
import chartIcon from 'src/static/icChart.png'
import customersIcon from 'src/static/icCustomers.png'
import revenueIcon from 'src/static/icRevenue.png'
import ArrowImg from 'src/static/icArrow.png'
import { useSelector } from 'react-redux'
import { avaInterviewSelectors } from 'src/redux'
import { ButtonBase, TitleText } from '../elements/styled-components'
import ReportNumberComponent from './ReportNumberComponent'
import AvaReportBlockContainer from './AvaReportBlockContainer'

const Container = styled.div``

const TitleTextStyled = styled(TitleText)`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 19px;

  @media screen and (max-width: 1280px) {
    font-size: 24px;
  }
`

const SubtitleTextStyled = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #222b45;
  margin-bottom: 32px;
`

const ReportBlockWrapper = styled.div`
  position: relative;
`

const DataRowSingle = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    margin-bottom: 0;
    overflow: hidden;
  }
`

const DataRow = styled(DataRowSingle)`
  justify-content: space-between;

  & > div {
    width: 48%;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 1280px) {
      width: 100%;
      margin-bottom: 56px;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`

const DetailsText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #222b45;
  margin-top: 10px;
`

const KeyNumbersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 18px;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`

const KeyNumbersBlock = styled.div`
  border: 1px solid #e1e3ea;
  border-radius: 8px;
`

const KeyNumbersBigBlock = styled(KeyNumbersBlock)`
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  height: 344px;

  @media screen and (max-width: 1280px) {
    height: auto;
    width: 100%;
    display: flex;
    padding: 15px 24px;
  }
`

const KeyNumberSideBlock = styled.div`
  flex: 1;
  flex-wrap: wrap;
  display: flex;

  @media screen and (max-width: 1280px) {
    flex-direction: row;
    min-height: 135px;
    justify-content: space-between;
    margin-bottom: 1%;
  }

  @media screen and (max-width: 900px) {
    min-height: 0;
    margin-bottom: 2%;
  }
`

const KeyNumbersMeduimBlock = styled(KeyNumbersBlock)`
  width: calc(100% - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 24px;

  @media screen and (max-width: 1280px) {
    width: 64%;
    margin-right: 0;
    height: 100%;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    height: unset;
    margin-bottom: 2%;
  }
`

const KeyNumbersSmallBlock = styled(KeyNumbersBlock)<{ column?: boolean }>`
  width: calc(50% - 30px);
  margin-right: 30px;
  align-items: center;
  padding-left: ${({ column }) => (column ? '0' : '50px')};
  display: flex;
  height: calc(50% - 15px);
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: ${({ column }) => (column ? 'center' : 'unset')};

  @media screen and (max-width: 1280px) {
    width: 17%;
    margin-right: 0;
    height: 100%;
    padding: 15px 24px;
    align-items: flex-start;
  }

  @media screen and (max-width: 900px) {
    width: 49%;
    height: 164px;
  }
`

const CompanyNameBlock = styled.div``

const CompanyNameStyled = styled.div`
  font-weight: 500;
  font-size: 34px;
  color: #222b45;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`

const CompanyDetailsStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #222b45;
  white-space: break-spaces;
`

const CompanyWebsiteButton = styled(ButtonBase)`
  position: relative;
  text-align: center;
  background: #222b45;
  color: white;
  border-radius: 42px;
  padding: 17px 60px 18px 29px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  min-width: fit-content;

  @media screen and (max-width: 1280px) {
    padding: 10px 38px 10px 10px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 40px;
    top: 21px;
    width: 14px;
    height: 12px;
    background: url(${ArrowImg});
    background-size: 100% 100%;

    @media screen and (max-width: 1280px) {
      right: 16px;
      top: 16px;
    }
  }
`

const EmployeesCountStyled = styled.div`
  font-weight: 500;
  font-size: 34px;
  line-height: 16px;
  color: #222b45;
  margin-bottom: 20px;

  @media screen and (max-width: 1280px) {
    line-height: 51px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`

const EmployeesLabelStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #222b45;
  margin-bottom: 15px;
`

const EmployessIcon = styled.img`
  width: 106px;
  height: 42px;
  object-fit: contain;
`

const NoDataOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.54);
  backdrop-filter: blur(12.5px);

  overflow: hidden;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
`

const NoDataTitle = styled.div`
  color: #222b45;
  font-family: Rubik;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
`

const NoDataText = styled.div`
  color: #3b424f;
  text-align: center;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
  height: 60px;
  white-space: break-spaces;
`

const NoDataInputContainer = styled.div<{ disabled?: boolean }>`
  width: 652px;
  height: 69px;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  padding: 0 32px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

const StyledInput = styled.input`
  flex: 1;
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 500;
  height: fit-content;
  outline: none;
`

const NoDataButton = withProps()(styled(ButtonBase)`
  padding: 16px;
  color: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`)

const CompanyOverviewComponent = () => {
  const interview = useSelector(avaInterviewSelectors.data)
  const [noDataTitle, setNoDataTitle] = useState('Company overview is not available')
  const [noDataText, setNoDataText] = useState(
    `This can happen if the company is newly founded,\nvery small, or its name was misspelt. Let's give it another shot!`
  )
  const [newCompanyName, setNewCompanyName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const openCompanyWebsite = useCallback(() => {
    let { website } = interview.position.company_overview
    if (!website.startsWith('http')) {
      website = `https://${website}`
    }
    window.open(website, '_blank', 'noreferrer')
  }, [interview])

  const isEmpty = (obj) => {
    return !obj || Object.keys(obj).length === 0
  }

  const handleGetInsightsClick = useCallback(() => {
    setNoDataTitle('Loading...')
    setNoDataText('This process takes approximately 1 minute.')
    setIsLoading(true)

    setTimeout(() => {
      setNoDataTitle(`We couldn't find data on ${newCompanyName}`)
      setNoDataText(
        `This can happen if the company is newly founded,\nvery small, or its name was misspelt. Let's give it another shot!`
      )
      setIsLoading(false)
    }, 5000)
  }, [newCompanyName])

  if (!interview) return undefined
  const hasCompany =
    !isEmpty(interview.position?.company_overview) && interview.position?.company_valid

  return (
    <Container>
      <TitleTextStyled>Company overview</TitleTextStyled>
      <SubtitleTextStyled>
        Key insights to help you prepare for your Interview
      </SubtitleTextStyled>
      <ReportBlockWrapper>
        <AvaReportBlockContainer title="Key numbers" icon={keynumbersIcon}>
          <KeyNumbersContainer>
            <KeyNumberSideBlock>
              <KeyNumbersMeduimBlock>
                <CompanyNameBlock>
                  <CompanyNameStyled>{interview.position.company}</CompanyNameStyled>
                  <CompanyDetailsStyled>
                    {interview.position.company_overview.industry} company{'\n'}
                    Headquartered in {interview.position.company_overview.headquarters}
                  </CompanyDetailsStyled>
                </CompanyNameBlock>
                <CompanyWebsiteButton onClick={openCompanyWebsite}>
                  Company Website
                </CompanyWebsiteButton>
              </KeyNumbersMeduimBlock>
              <KeyNumbersSmallBlock>
                <ReportNumberComponent
                  icon={foundedIcon}
                  text="Founded"
                  value={interview.position.company_overview.founded}
                  color="#B202FF"
                />
              </KeyNumbersSmallBlock>
              <KeyNumbersSmallBlock column>
                <EmployeesCountStyled>
                  {interview.position.company_overview.number_of_employees}
                </EmployeesCountStyled>
                <EmployeesLabelStyled>employees</EmployeesLabelStyled>
                <EmployessIcon src={eployeesGroupIcon} alt="eployeesGroupIcon" />
              </KeyNumbersSmallBlock>
            </KeyNumberSideBlock>
            <KeyNumbersBigBlock>
              <ReportNumberComponent
                icon={fundingIcon}
                text="Funding"
                value={interview.position.company_overview.funding}
                color="#B202FF"
                unavailable={!interview.position.company_overview.funding}
              />
              <ReportNumberComponent
                icon={chartIcon}
                text="Valuation"
                value={interview.position.company_overview.valuation}
                color="#F1BE2B"
                unavailable={!interview.position.company_overview.valuation}
              />
              <ReportNumberComponent
                icon={revenueIcon}
                text="Revenue"
                value={interview.position.company_overview.revenue}
                color="#F1BE2B"
                unavailable={!interview.position.company_overview.revenue}
              />
              <ReportNumberComponent
                icon={customersIcon}
                text="Number of customers"
                value={interview.position.company_overview.number_of_customers}
                color="#00DE9F"
                unavailable={!interview.position.company_overview.number_of_customers}
              />
            </KeyNumbersBigBlock>
          </KeyNumbersContainer>
        </AvaReportBlockContainer>
        {!hasCompany && (
          <NoDataOverlay>
            <NoDataTitle>{noDataTitle}</NoDataTitle>
            <NoDataText>{noDataText}</NoDataText>
            <NoDataInputContainer disabled={isLoading}>
              <StyledInput onChange={(ev) => setNewCompanyName(ev.target.value)} />
              {!isLoading && (
                <NoDataButton
                  onClick={handleGetInsightsClick}
                  disabled={newCompanyName === ''}
                >
                  Get insights
                </NoDataButton>
              )}
            </NoDataInputContainer>
          </NoDataOverlay>
        )}
      </ReportBlockWrapper>
      {hasCompany && (
        <>
          <DataRow>
            <AvaReportBlockContainer
              title={`${interview.position.company} at a glance`}
              icon={companyIcon}
            >
              <DetailsText>{interview.position.company_values}</DetailsText>
            </AvaReportBlockContainer>
            <AvaReportBlockContainer
              title="Market and competitive landscape"
              icon={marketIcon}
            >
              <DetailsText>{interview.position.company_market}</DetailsText>
            </AvaReportBlockContainer>
          </DataRow>
        </>
      )}
    </Container>
  )
}

export default CompanyOverviewComponent
