/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import styled from '@emotion/styled'
import React from 'react'
import summaryIcon from 'src/static/report_summary.svg'
import withProps from 'recompose/withProps'
import communicationIcon from 'src/static/report_communication.svg'
import { useSelector } from 'react-redux'
import { avaInterviewSelectors } from 'src/redux'
import { ReportSubtitleText, ReportTitle } from './report-styled-components'
import AvaReportBlockContainer from './AvaReportBlockContainer'

const Container = styled.div``

const BlockContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }

  & > div {
    width: 48%;

    @media screen and (max-width: 1280px) {
      width: 100%;
      margin-bottom: 56px;
    }
  }
`

const TerminologyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 17px;
`

const TerminologyItem = styled.div`
  background: #fff;
  border: 1px solid #e1e3ea;
  border-radius: 8px;
  padding: 22px;
  margin-bottom: 16px;
`

const TerminologyTitle = styled.div<{ useMargin?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #222b45;
  margin-bottom: ${({ useMargin }) => (useMargin ? 16 : 0)}px;
`

const TerminologyText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #222b45;
`

const RecommendationItem = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e1e3ea;
  border-radius: 8px;
  padding: 22px;
  margin-bottom: 16px;
`

const RecommendationData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const RecommendationLink = withProps()(styled.a`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  cursor: pointer;
  height: fit-content;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return color
    }};
  }
`)

const ProTipsComponent = () => {
  const interview = useSelector(avaInterviewSelectors.data)
  if (!interview) return undefined

  return (
    <Container>
      <ReportTitle>ProTips</ReportTitle>
      <ReportSubtitleText>Resources and tips to help you shine</ReportSubtitleText>
      <BlockContainer>
        <AvaReportBlockContainer
          title={`${interview.position.position} common terminology`}
          icon={communicationIcon}
        >
          <TerminologyContainer>
            {interview.position.position_terminologies
              .filter((item) => item.name && item.description)
              .map((item, index) => (
                <TerminologyItem key={index}>
                  <TerminologyTitle useMargin>{item.name}</TerminologyTitle>
                  <TerminologyText>{item.description}</TerminologyText>
                </TerminologyItem>
              ))}
          </TerminologyContainer>
        </AvaReportBlockContainer>
        <AvaReportBlockContainer title="Recommended resources" icon={summaryIcon}>
          <TerminologyContainer>
            {interview.position.position_readings.map((item, index) => (
              <RecommendationItem key={index}>
                <RecommendationData>
                  <TerminologyTitle>{item.title}</TerminologyTitle>
                </RecommendationData>
                <RecommendationLink href={item.link} target="_blank">
                  Visit →
                </RecommendationLink>
              </RecommendationItem>
            ))}
          </TerminologyContainer>
        </AvaReportBlockContainer>
      </BlockContainer>
    </Container>
  )
}

export default ProTipsComponent
