/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import useStateRef from 'react-usestateref'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AvaReportProgress } from 'src/api'
import { SpeechTexts } from 'src/ava/speechService'
import * as interviewSelectors from 'src/interview/selectors'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { NavButton } from '../elements/styled-components'
import LiveText from '../LiveText'
import {
  avaInterviewSelectors,
  avaSaveInfoAction,
  updateAvaInterviewAction,
} from '../../../redux'
import CheckBox from '../CheckBox'
import LoadingBar from '../elements/LoadingBar'

const AnimationFirstSpeedMs = 166
const AnimationLastSpeedMs = 2000

const Container = styled.div`
  margin: 104px auto 0;
  width: 60%;

  @media screen and (max-width: 1280px) {
    margin: 70px auto 0;
  }

  ${isMobileOnly &&
  css`
    background: white;
    width: 100%;
    margin: 0 !important;
    flex: 1;
    padding: 0 35px 16px 35px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 96px);
    overflow: auto;
  `}
`

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  min-height: 114px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;

  ${isMobileOnly &&
  css`
    font-size: 24px;
    line-height: 32px;
    height: auto;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const SubTitle = styled(Title)`
  min-height: 22px;
  font-weight: 400;
  font-size: 18px;
  line-height: normal;
  margin-bottom: 60px;
`

const NavButtonStyled = styled(NavButton)`
  width: fit-content;

  ${isMobileOnly &&
  css`
    margin: auto 0 16px 0;
    width: 100%;
  `}
`

const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #000;
  padding-top: 32px;
  margin-top: 32px;

  ${isMobileOnly &&
  css`
    flex-direction: column-reverse;
    margin: auto 0 0 0;
    border: none;
    padding: 0;
    align-items: start;
    position: sticky;
    bottom: 0;
    background: #fff;
  `}
`

const CheckBoxLabel = styled.div<{ error?: boolean }>`
  color: ${({ error }) => (error ? '#FF1F44' : '#737991')};
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`

const LoadingText = styled.div<{ disabled?: boolean }>`
  color: rgba(115, 121, 145, 0.6);
  font-family: Rubik;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`

const LoadItems = [
  'Analyzing your answers',
  'Comparing your performance to best practices',
  'Identifying key improvement areas',
  'Personalizing your insights',
]

interface IProps {
  onNext: () => void
}

const LoadingComponent = ({ onNext }: IProps) => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const [showContent, setShowContent] = useState(false)
  const [progress, setProgress, progressRef] = useStateRef(0)
  const [loadingText, setLoadingText] = useState(LoadItems[0])
  const [canGoNext, setCanGoNext] = useState(false)
  const [animationSpeedMs, setAnimationSpeedMs] = useState(0)

  const avaInterview = useSelector(avaInterviewSelectors.data)
  const agency = useSelector(interviewSelectors.agency)

  const setAgreeToShare = (checked) => {
    dispatch(updateAvaInterviewAction({ ...avaInterview, agree_to_share: checked }))
  }

  const runProgress = async () => {
    while (progressRef.current < 0.91) {
      await new Promise((resolve) => setTimeout(resolve, AnimationFirstSpeedMs))
      setProgress((v) => v + 0.01)
    }
    while (progressRef.current < 0.99) {
      await new Promise((resolve) => setTimeout(resolve, AnimationLastSpeedMs))
      setProgress((v) => v + 0.01)
    }
  }

  const handleNext = () => {
    dispatch(
      avaSaveInfoAction({
        interviewToken,
        interview: { agree_to_share: avaInterview.agree_to_share },
      })
    )
    onNext()
  }

  useEffect(() => {
    if (avaInterview.agree_to_share === null) {
      dispatch(updateAvaInterviewAction({ ...avaInterview, agree_to_share: true }))
    }
    if (avaInterview.report_progress >= AvaReportProgress.RECOMMENDATIONS_GENERATED) {
      if (progress > 0) {
        setProgress(1)
        setTimeout(() => {
          setCanGoNext(true)
        }, 1000)
      } else {
        setCanGoNext(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaInterview])

  useEffect(() => {
    if (
      showContent &&
      avaInterview.report_progress < AvaReportProgress.RECOMMENDATIONS_GENERATED
    ) {
      runProgress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent])

  useEffect(() => {
    if (progress > 1) {
      setProgress(1)
      return
    }
    if (progress < 0.36) {
      setLoadingText(LoadItems[0])
    } else if (progress < 0.6) {
      setLoadingText(LoadItems[1])
    } else if (progress < 0.85) {
      setLoadingText(LoadItems[2])
    } else {
      setLoadingText(LoadItems[3])
    }

    if (progress < 0.91 || progress === 1) {
      setAnimationSpeedMs(AnimationFirstSpeedMs)
    } else {
      setAnimationSpeedMs(AnimationLastSpeedMs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, animationSpeedMs])

  return (
    <Container>
      <Title>
        <LiveText
          text={canGoNext ? SpeechTexts.ReportIsReady : SpeechTexts.SitBackWhileIReview}
          onFinish={() => setShowContent(true)}
        />
      </Title>
      {showContent && !canGoNext && (
        <SubTitle>
          This should only take a moment. Your report will also be sent by email.
        </SubTitle>
      )}
      {showContent && !canGoNext && (
        <LoadingContainer>
          <LoadingBar progress={progress} animationSpeedMs={animationSpeedMs} />
          <LoadingText>{loadingText}</LoadingText>
        </LoadingContainer>
      )}
      {canGoNext && showContent && (
        <BottomContainer>
          <CheckBox onChange={setAgreeToShare} value={avaInterview.agree_to_share}>
            <CheckBoxLabel>I agree to share my report with {agency.name}</CheckBoxLabel>
          </CheckBox>
          <NavButtonStyled onClick={handleNext} hideIcon>
            Show me!
          </NavButtonStyled>
        </BottomContainer>
      )}
    </Container>
  )
}

export default LoadingComponent
