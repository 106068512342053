import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as toastr } from 'react-redux-toastr'

import admin from '../admin/reducers'
import {
  interviewsReducer,
  agencyInterviewReducer,
  statsReducer,
  sendToApplicantReducer,
  saveSessionReportReducer,
  interviewCompleteReducer,
  interviewToEndReducer,
} from '../agency/reducers'
import {
  updateOrderInfoReducer,
  updateAvailabilityInfoRedcuer,
  updateSchedulingInfoReducer,
  updatePostSessionInfoReducer,
} from '../agency/redux'
import auth from '../auth/reducers'
import {
  assignTimeSlotReducer,
  createBookingReducer,
  createIntentReducer,
  createPaymentReducer,
  declineAvailabilityReducer,
  interviewReducer,
  paySucceedReducer,
  submitAvailabilityReducer,
} from '../interview/reducers'
import { conversationTokenReducer, linkConversationReducer } from '../interview/redux'

import { agencyDataReducer } from './data/agency'
import { callNetworkLocalDataReducer } from './data/callNetworkLocal'
import { callNetworkRemoteDataReducer } from './data/callNetworkRemote'
import { conversationReducer } from './data/conversation'
import { systemSettingsDataReducer } from './data/systemSettings'
import { applicantNotesDataReducer } from './data/applicantNotes'
import { bookingInfoDataReducer } from './data/bookingInfo'

import { avaInterviewDataReducer } from './data/ava/interview'
import { avaInterviewsDataReducer } from './data/ava/interviews'
import { avaSettingsDataReducer } from './data/ava/settings'
import { synthesizedVoiceDataReducer } from './data/ava/synthesizedVoice'

import { bookingStatusDataReducer } from './data/interviewParams/bookingStatus'
import { completedPreMeetingDataReducer } from './data/interviewParams/completedPreMeeting'

import { assignOpsRequestReducer } from './requests/assignOps'
import { createAgencyRequestReducer } from './requests/createAgency'
import { deleteAgencyInterviewRequestReducer } from './requests/deleteAgencyInterview'
import { downloadAdminReportRequestReducer } from './requests/downloadAdminReport'
import { downloadAgencyReportRequestReducer } from './requests/downloadAgencyReport'
import { editAgencyRequestReducer } from './requests/editAgency'
import { getAgencyRequestReducer } from './requests/getAgency'
import { getConversationReducer } from './requests/getConversation'
import { getSystemSettingsRequestReducer } from './requests/getSystemSettings'
import { setInterviewTimestampReducer } from './requests/setInterviewTimestamp'
import { setRecordingTimestampReducer } from './requests/setRecordingTimestamp'
import { updateSystemSettingsRequestReducer } from './requests/updateSystemSettings'
import { sendApplicantNoteRequestReducer } from './requests/sendApplicantNote'
import { removeApplicantNoteRequestReducer } from './requests/removeApplicantNote'
import { getApplicantNotesRequestReducer } from './requests/getApplicantNotes'
import { adminRescheduleInterviewRequestReducer } from './requests/adminRescheduleInterview'
import { candidateRescheduleInterviewRequestReducer } from './requests/candidateRescheduleInterview'
import { bulkCreateInterviewRequestReducer } from './requests/bulkCreateInterview'

import { avaGetInterviewRequestReducer } from './requests/ava/getInterview'
import { avaGetInterviewsRequestReducer } from './requests/ava/getInterviews'
import { avaSaveAnswerRequestReducer } from './requests/ava/saveAnswer'
import { avaSaveInfoRequestReducer } from './requests/ava/saveInfo'
import { avaSynthesizeVoiceRequestReducer } from './requests/ava/synthesizeVoice'
import { moveToAlternativeLinkRequestReducer } from './requests/moveToAlternativeLink'
import { expertNotesDataReducer } from './data/expertNotes'
import { saveExpertNoteRequestReducer } from './requests/saveExpertNote'
import { removeExpertNoteRequestReducer } from './requests/removeExpertNote'
import { createInterviewRequestReducer } from './requests/createInterview'

import {
  avaStepDataReducer,
  avaOnboardingStepDataReducer,
  avaIntroStepDataReducer,
} from './data/ava/step'
import { getBulkInterviewsRequestReducer } from './requests/getBulkInterviews'
import { bulkInterviewsDataReducer } from './data/bulkInterviews'

import { activeNoteReducer } from '../report/redux'
import { avaQuestionIndexReducer } from './data/ava/questionIndex'
import { avaInterviewPromptsDataReducer } from './data/ava/interviewPrompts'
import { avaGetInterviewPromptsRequestReducer } from './requests/ava/getInterviewPrompts'
import { requestDemoRequestReducer } from './requests/requestDemo'
import { subscribeRequestReducer } from './requests/subscribe'
import { avaCreateInterviewRequestReducer } from './requests/ava/createInterview'
import { avaDataDataReducer } from './data/ava/data'
import { avaReplaceQuestionRequestReducer } from './requests/ava/replaceQuestion'

const createRootReducer = (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    admin,
    agency: combineReducers({
      agency: agencyDataReducer,
      interviews: interviewsReducer,
      interview: agencyInterviewReducer,
      stats: statsReducer,
      updateOrderInfo: updateOrderInfoReducer,
      updateAvailabilityInfo: updateAvailabilityInfoRedcuer,
      updateSchedulingInfo: updateSchedulingInfoReducer,
      updatePostSessionInfo: updatePostSessionInfoReducer,
      sendToApplicant: sendToApplicantReducer,
      saveSessionReport: saveSessionReportReducer,
      interviewComplete: interviewCompleteReducer,
      interviewToEnd: interviewToEndReducer,
    }),
    applicantNotes: applicantNotesDataReducer,
    auth,
    ava: combineReducers({
      interview: avaInterviewDataReducer,
      interviewPrompts: avaInterviewPromptsDataReducer,
      interviews: avaInterviewsDataReducer,
      settings: avaSettingsDataReducer,
      data: avaDataDataReducer,
      step: avaStepDataReducer,
      onboardingStep: avaOnboardingStepDataReducer,
      introStep: avaIntroStepDataReducer,
      synthesizedVoice: synthesizedVoiceDataReducer,
      questionIndex: avaQuestionIndexReducer,
    }),
    bookingInfo: bookingInfoDataReducer,
    bulkInterviews: bulkInterviewsDataReducer,
    call: combineReducers({
      networkLocal: callNetworkLocalDataReducer,
      networkRemote: callNetworkRemoteDataReducer,
    }),
    expertNotes: expertNotesDataReducer,
    interview: combineReducers({
      interview: interviewReducer,
      createBooking: createBookingReducer,
      submitAvailability: submitAvailabilityReducer,
      declineAvailability: declineAvailabilityReducer,
      linkConversation: linkConversationReducer,
      assignTimeSlot: assignTimeSlotReducer,
      createIntent: createIntentReducer,
      createPayment: createPaymentReducer,
      paySucceed: paySucceedReducer,
    }),
    interviewParams: combineReducers({
      bookingStatus: bookingStatusDataReducer,
      completedPreMeeting: completedPreMeetingDataReducer,
    }),
    requests: combineReducers({
      assignOps: assignOpsRequestReducer,
      bulkCreateInterview: bulkCreateInterviewRequestReducer,
      createAgency: createAgencyRequestReducer,
      deleteAgencyInterview: deleteAgencyInterviewRequestReducer,
      downloadAdminReport: downloadAdminReportRequestReducer,
      downloadAgencyReport: downloadAgencyReportRequestReducer,
      editAgency: editAgencyRequestReducer,
      getAgency: getAgencyRequestReducer,
      getApplicantNotes: getApplicantNotesRequestReducer,
      getConversation: getConversationReducer,
      getSystemSettings: getSystemSettingsRequestReducer,
      removeApplicantNote: removeApplicantNoteRequestReducer,
      adminRescheduleInterview: adminRescheduleInterviewRequestReducer,
      candidateRescheduleInterview: candidateRescheduleInterviewRequestReducer,
      sendApplicantNote: sendApplicantNoteRequestReducer,
      setInterviewTimestamp: setInterviewTimestampReducer,
      setRecordingTimestamp: setRecordingTimestampReducer,
      updateSystemSettings: updateSystemSettingsRequestReducer,
      moveToAlternativeLink: moveToAlternativeLinkRequestReducer,
      saveExpertNote: saveExpertNoteRequestReducer,
      removeExpertNote: removeExpertNoteRequestReducer,
      avaCreateInterview: avaCreateInterviewRequestReducer,
      avaGetInterview: avaGetInterviewRequestReducer,
      avaGetInterviewPrompts: avaGetInterviewPromptsRequestReducer,
      avaGetInterviews: avaGetInterviewsRequestReducer,
      avaSaveAnswer: avaSaveAnswerRequestReducer,
      avaSaveInfo: avaSaveInfoRequestReducer,
      avaSynthesizeVoice: avaSynthesizeVoiceRequestReducer,
      createInterview: createInterviewRequestReducer,
      getBulkInterviews: getBulkInterviewsRequestReducer,
      requestDemo: requestDemoRequestReducer,
      subscribe: subscribeRequestReducer,
      avaReplaceQuestion: avaReplaceQuestionRequestReducer,
    }),
    toastr,
    symbl: combineReducers({
      conversation: conversationReducer,
      conversationToken: conversationTokenReducer,
    }),
    systemSettings: systemSettingsDataReducer,
    report: combineReducers({
      activeNoteInfo: activeNoteReducer,
    }),
  })

  return (state, action) => {
    let newState = state
    if (action.type === 'RESET_STORE') {
      newState = undefined
    }

    return appReducer(newState, action)
  }
}

export default createRootReducer
