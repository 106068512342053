import styled from '@emotion/styled'
import moment from 'moment'
import React, { ChangeEvent, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import {
  AgencyExpertSessionType,
  AgencyTheme,
  ApplicantAvailabilityFlow,
  BusinessModel,
  IAgency,
  IAgencyPriceTier,
} from '../../api'
import { Button, Input, Select, Text } from '../../components'
import { agencySelectors, createAgencyAction, editAgencyAction } from '../../redux'
import { canChangeAgencyPositions } from '../../utils/jwtToken'
import preventImageCache from '../../utils/preventImageCache'
import { EmailRegex, UrlRegex } from '../../utils/regex'

import CheckBox from '../components/CheckBox'
import Layout from '../Layout'

import AgencyAdmins from './agency/AgencyAdmins'
import AgencyPriceTiers from './agency/AgencyPriceTiers'
import IgnoredEmails from './agency/IgnoredEmails'
import InternalExperts from './agency/InternalExperts'
import ValidEmailDomains from './agency/ValidEmailDomains'
import AgencyPositions from './agency/AgencyPositions'
import AgencyManagers from './agency/AgencyManagers'

export enum AgencyFormType {
  Create = 'Create',
  Edit = 'Edit',
}

const AgencyThemes = [
  { value: AgencyTheme.NORMAL, label: 'Normal' },
  { value: AgencyTheme.DEI, label: 'DEI' },
  { value: AgencyTheme.EXECUTIVE, label: 'Executive' },
]

const BusinessModels = [
  { value: BusinessModel.B2B, label: 'B2B' },
  { value: BusinessModel.B2B2C, label: 'B2B2C' },
  { value: BusinessModel.B2C, label: 'B2C' },
  { value: BusinessModel.B2C_FREEMIUM, label: 'B2C Freemium' },
]

const Currencies = [
  { value: 'USD', label: 'USD' },
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
  { value: 'ILS', label: 'ILS' },
  { value: 'CAD', label: 'CAD' },
  { value: 'AUD', label: 'AUD' },
]

const ExpertSessionTypes = [
  {
    value: AgencyExpertSessionType.FULL,
    label: 'Coaching for every step of your career journey',
  },
  { value: AgencyExpertSessionType.MOCK, label: 'Mock interview only' },
]

const TimeZones = moment.tz.names().map((value) => {
  const tz = moment.tz(value)
  return { label: `${value}, ${tz.format('z')}, GMT ${tz.format('Z')}`, value }
})

const WelcomePages = [
  { value: 'landing', label: 'Landing Page' },
  { value: 'form', label: 'Order Form' },
]

const readLogoFile = async (e: ChangeEvent<HTMLInputElement>) => {
  const [file] = e.target.files
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

const Form = styled.form`
  padding: 15px 0;
`

const Container = styled.div`
  border: 1px solid #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const Title = styled.label`
  font-weight: bold;
  font-size: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
    margin-bottom: 20px;
  }
`

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;

  border: 1px solid #e1e3ea;
  border-radius: 5px;
  background-color: #f5f6f8 !important;
`

const Logo = styled.img`
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
`

const Label = styled.label`
  font-size: 0.85rem;
  margin-bottom: 10px;
  width: 50%;
  text-align: center;
`

const Options = styled.div`
  border: 1px dashed #e1e3ea;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div {
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 0;
  }
`

const ButtonStyled = styled(Button)`
  && {
    margin-top: 40px;
  }
`

const ApiKeys = styled.div`
  border-bottom: 1px solid dashed;
  border-left: 1px solid dashed;
  position: absolute;
  right: 0;
  top: 0;
`

const ApiKey = styled(Text)`
  padding: 0;
`

interface IForm {
  agency: Partial<IAgency> & { email_domain_validations: any }
  senior: Pick<IAgencyPriceTier, 'price_max' | 'price_min'>
  team_lead: Pick<IAgencyPriceTier, 'price_max' | 'price_min'>
  executive: Pick<IAgencyPriceTier, 'price_max' | 'price_min'>
}

interface IProps {
  mode: AgencyFormType
}

const AgencyForm = ({ mode }: IProps) => {
  const dispatch = useDispatch()
  const agency = useSelector(agencySelectors.data)

  const showAgencyPositions = canChangeAgencyPositions()

  const companyLogoInputRef = useRef<HTMLInputElement>()
  const negativeLogoInputRef = useRef<HTMLInputElement>()

  const onSubmit = (payload: IForm) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(`Are you sure you want to save changes?`)
    if (!confirmed) return

    const {
      agency: {
        has_ab_testing,
        use_new_applicant_availability_flow,
        agency_admins,
        agency_positions,
        agency_managers,
        internal_experts,
        email_domain_validations: domains,
        ...agencyData
      },
      senior,
      team_lead,
      executive,
    } = payload

    // eslint-disable-next-line no-nested-ternary
    const applicant_availability_flow = use_new_applicant_availability_flow
      ? has_ab_testing && !agencyData.allow_direct_registration
        ? ApplicantAvailabilityFlow.AB_FLOW
        : ApplicantAvailabilityFlow.NEW_FLOW
      : ApplicantAvailabilityFlow.OLD_FLOW

    const updatedAgency = {
      ...agencyData,
      applicant_availability_flow,
      business_model: agencyData.business_model ?? BusinessModels[0].value,
      positions: agencyData.enable_ava ? agency_positions : undefined,
      email_domain_validations: (domains || []).map(({ value }) => value),
      display_topbar_footer:
        agencyData.enable_ava && agencyData.enable_experts
          ? agencyData.display_topbar_footer
          : false,
    }
    const priceTiers = { senior, team_lead, executive }

    if (agency.id)
      dispatch(
        editAgencyAction({
          agencyId: agency.id,
          agency: updatedAgency,
          agencyAdmins: agency_admins,
          agencyManagers: agency_managers,
          internalExperts: internal_experts,
          priceTiers,
        })
      )
    else dispatch(createAgencyAction({ agency: updatedAgency, priceTiers }))
  }

  const {
    control,
    errors,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
    watch,
  } = useForm<IForm>({
    mode: 'all',
    defaultValues: {
      agency,
      senior: { price_min: undefined, price_max: undefined },
      team_lead: { price_min: undefined, price_max: undefined },
      executive: { price_min: undefined, price_max: undefined },
    },
  })

  const isExpertAgency = watch('agency.enable_experts') as boolean
  const isAvaAgency = watch('agency.enable_ava') as boolean

  const businessModel = watch('agency.business_model') as BusinessModel
  const isB2C = businessModel === BusinessModel.B2C

  const allowDirectBooking = watch('agency.allow_direct_registration') as boolean
  const newApplicantAvailabilityFlow = watch(
    'agency.use_new_applicant_availability_flow'
  ) as boolean

  useEffect(() => {
    if (agency) {
      const { email_domain_validations: domains } = agency
      const agencyPriceTiers = agency?.agency_price_tiers || []
      const senior = agencyPriceTiers.find((t) => t.name === 'Senior Collaborator') || {}
      const team_lead = agencyPriceTiers.find((t) => t.name === 'Team Lead') || {}
      const executive = agencyPriceTiers.find((t) => t.name === 'Executive') || {}

      reset({
        agency: {
          ...agency,
          email_domain_validations: (domains || []).map((value) => ({ value })),
        },
        executive,
        senior,
        team_lead,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, mode])

  return (
    <Layout title={`${mode} Agency`} hideBg fullBody>
      <>
        {!!agency.api_keys?.length && (
          <ApiKeys>
            {agency.api_keys.map(({ key }) => (
              <ApiKey>{key}</ApiKey>
            ))}
          </ApiKeys>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Title>Agency Type</Title>

            <Options>
              <Controller
                name="agency.enable_experts"
                render={({ onChange, value, name }) => (
                  <CheckBox
                    label="Expert coaching"
                    onChange={onChange}
                    value={value ?? false}
                    name={name}
                  />
                )}
                control={control}
              />

              <Controller
                name="agency.enable_ava"
                render={({ onChange, value, name }) => (
                  <CheckBox
                    label="Ava coaching"
                    onChange={onChange}
                    value={value ?? false}
                    name={name}
                  />
                )}
                control={control}
              />
            </Options>
          </Container>

          {(isExpertAgency || isAvaAgency) && (
            <>
              <Container>
                <Title>Customization</Title>

                <Row>
                  <Controller
                    name="agency.company_logo"
                    control={control}
                    render={({ onChange, value }) => (
                      <LogoContainer>
                        <Logo
                          alt="Click to select logo"
                          src={
                            value?.url ? preventImageCache(value.url) : (value as string)
                          }
                          onClick={() => companyLogoInputRef.current.click()}
                        />
                        <input
                          hidden
                          ref={companyLogoInputRef}
                          type="file"
                          accept="image/*"
                          onChange={async (e) => onChange(await readLogoFile(e))}
                        />
                        <Label>Company Logo</Label>
                      </LogoContainer>
                    )}
                  />

                  {isExpertAgency && (
                    <Controller
                      name="agency.negative_logo"
                      control={control}
                      render={({ onChange, value }) => (
                        <LogoContainer>
                          <Logo
                            alt="Click to select logo"
                            src={
                              value?.url
                                ? preventImageCache(value.url)
                                : (value as string)
                            }
                            onClick={() => negativeLogoInputRef.current.click()}
                          />
                          <input
                            hidden
                            ref={negativeLogoInputRef}
                            type="file"
                            accept="image/*"
                            onChange={async (e) => onChange(await readLogoFile(e))}
                          />
                          <Label>Negative Logo</Label>
                        </LogoContainer>
                      )}
                    />
                  )}
                </Row>

                <Row>
                  <Controller
                    as={Input}
                    label="Color"
                    name="agency.main_color"
                    control={control}
                    error={errors.agency?.main_color}
                    rules={{
                      validate: {
                        valid: (value: string) =>
                          !value ||
                          /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value) ||
                          'Wrong color format',
                      },
                    }}
                    defaultValue=""
                  />

                  {isExpertAgency && (
                    <Controller
                      name="agency.theme"
                      control={control}
                      defaultValue={AgencyThemes[0].value}
                      render={({ onChange, value, name: selectName }) => (
                        <Select
                          name={selectName}
                          label="Theme"
                          onChange={onChange}
                          value={value}
                          options={AgencyThemes}
                          useTheme
                        />
                      )}
                    />
                  )}
                </Row>
              </Container>

              <Container>
                <Title>General</Title>

                <Row>
                  <Controller
                    as={Input}
                    label="Name"
                    name="agency.name"
                    control={control}
                    error={errors.agency?.name}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                  <Controller
                    as={Input}
                    label="Internal Name"
                    name="agency.internal_name"
                    control={control}
                    error={errors.agency?.internal_name}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Row>

                <Row>
                  <Controller
                    as={Input}
                    label="Email"
                    name="agency.email"
                    control={control}
                    error={errors.agency?.email}
                    rules={{
                      required: true,
                      validate: {
                        valid: (value) =>
                          !value ||
                          EmailRegex.test(value) ||
                          "That format doesn't look right. Make sure there aren't any typos",
                      },
                    }}
                    defaultValue=""
                  />
                  <Controller
                    as={Input}
                    label="Subdomain"
                    name="agency.subdomain"
                    control={control}
                    error={errors.agency?.subdomain}
                    rules={{
                      required: true,
                      validate: {
                        valid: (value) =>
                          !value ||
                          /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/.test(value) ||
                          'Must be valid',
                      },
                    }}
                    defaultValue=""
                  />
                </Row>

                <Row>
                  <Controller
                    name="agency.direct_users_first_page"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value, name: selectName }) => (
                      <Select
                        name={selectName}
                        label="Welcome page (direct link)"
                        onChange={onChange}
                        value={value ?? ''}
                        options={WelcomePages}
                        useTheme
                        error={errors.agency?.direct_users_first_page}
                      />
                    )}
                  />
                  <Controller
                    name="agency.invited_users_first_page"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value, name: selectName }) => (
                      <Select
                        name={selectName}
                        label="Welcome page (invited link)"
                        onChange={onChange}
                        value={value ?? ''}
                        options={WelcomePages}
                        useTheme
                        error={errors.agency?.invited_users_first_page}
                      />
                    )}
                  />
                </Row>

                <Row>
                  <Controller
                    as={Input}
                    label="Success manager"
                    name="agency.success_manager_name"
                    control={control}
                    error={errors.agency?.success_manager_name}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                  <Controller
                    as={Input}
                    label="Success manager email"
                    name="agency.success_manager_email"
                    control={control}
                    error={errors.agency?.success_manager_email}
                    rules={{
                      required: true,
                      validate: {
                        valid: (value) =>
                          !value ||
                          EmailRegex.test(value) ||
                          "That format doesn't look right. Make sure there aren't any typos",
                      },
                    }}
                    defaultValue=""
                  />
                </Row>

                <Row>
                  <Controller
                    name="agency.time_zone"
                    control={control}
                    defaultValue=""
                    render={({ onChange, value, name: selectName }) => (
                      <Select
                        name={selectName}
                        label="Time zone"
                        onChange={onChange}
                        value={value ?? ''}
                        options={TimeZones}
                        useTheme
                        error={errors.agency?.time_zone}
                      />
                    )}
                  />
                </Row>

                <Options>
                  <Controller
                    name="agency.allow_direct_registration"
                    render={({ onChange, value, name }) => (
                      <CheckBox
                        label="Allow direct booking"
                        onChange={onChange}
                        value={value ?? false}
                        name={name}
                      />
                    )}
                    control={control}
                  />

                  <Controller
                    name="agency.display_topbar_footer"
                    render={({ onChange, value, name }) => (
                      <CheckBox
                        label="Display topbar and footer"
                        onChange={onChange}
                        value={isAvaAgency && isExpertAgency ? value ?? false : false}
                        name={name}
                        disabled={!isAvaAgency || !isExpertAgency}
                        tooltip={
                          !(isAvaAgency && isExpertAgency) &&
                          'Only available for Hybrid agencies'
                        }
                      />
                    )}
                    control={control}
                  />
                </Options>
              </Container>

              {isExpertAgency && (
                <Container>
                  <Title>Expert Settings</Title>

                  <Row>
                    <Controller
                      name="agency.expert_session_type"
                      control={control}
                      defaultValue={ExpertSessionTypes[0].value}
                      render={({ onChange, value, name: selectName }) => (
                        <Select
                          name={selectName}
                          label="Session type"
                          onChange={onChange}
                          value={value ?? ''}
                          options={ExpertSessionTypes}
                          useTheme
                        />
                      )}
                    />

                    <Controller
                      as={Input}
                      label="Special request"
                      name="agency.special_request"
                      control={control}
                      error={errors.agency?.special_request}
                      defaultValue=""
                    />
                  </Row>

                  <Row>
                    <Controller
                      as={Input}
                      label="Session Price"
                      name="agency.session_price"
                      control={control}
                      error={errors.agency?.session_price}
                      rules={{ required: true, min: 0, max: 999 }}
                      type="number"
                      disabled={isB2C}
                    />
                    <Controller
                      name="agency.currency"
                      control={control}
                      defaultValue=""
                      render={({ onChange, value, name: selectName }) => (
                        <Select
                          name={selectName}
                          label="Currency"
                          onChange={onChange}
                          value={value ?? ''}
                          options={Currencies}
                          useTheme
                          error={errors.agency?.currency}
                        />
                      )}
                    />
                  </Row>

                  <Row>
                    <Controller
                      name="agency.business_model"
                      control={control}
                      defaultValue=""
                      render={({ onChange, value, name: selectName }) => (
                        <Select
                          name={selectName}
                          label="Pricing model"
                          onChange={onChange}
                          value={value ?? ''}
                          options={BusinessModels}
                          useTheme
                          error={errors.agency?.business_model}
                        />
                      )}
                    />
                    <Controller
                      as={Input}
                      label="Duration"
                      name="agency.duration"
                      control={control}
                      error={errors.agency?.duration}
                      rules={{ required: true, min: 5, max: 199 }}
                      defaultValue=""
                      type="number"
                    />
                  </Row>

                  <Row>
                    <Controller
                      as={Input}
                      label="Backup meeting URL"
                      name="agency.meeting_url"
                      control={control}
                      error={errors.agency?.meeting_url}
                      rules={{
                        required: true,
                        validate: {
                          valid: (value) =>
                            value
                              ? UrlRegex.test(value) ||
                                "That format doesn't look right. Make sure there aren't any typos"
                              : true,
                        },
                      }}
                      defaultValue=""
                    />

                    <Controller
                      as={Input}
                      label="Survey URL"
                      name="agency.survey_url"
                      control={control}
                      error={errors.agency?.survey_url}
                      rules={{
                        required: true,
                        validate: {
                          valid: (value) =>
                            value
                              ? UrlRegex.test(value) ||
                                "That format doesn't look right. Make sure there aren't any typos"
                              : true,
                        },
                      }}
                      defaultValue=""
                    />
                  </Row>

                  <Row>
                    <Controller
                      as={Input}
                      label="Expert Onboarding URL"
                      name="agency.expert_onboarding_url"
                      control={control}
                      error={errors.agency?.expert_onboarding_url}
                      rules={{
                        required: true,
                        validate: {
                          valid: (value) =>
                            value
                              ? UrlRegex.test(value) ||
                                "That format doesn't look right. Make sure there aren't any typos"
                              : true,
                        },
                      }}
                      defaultValue=""
                    />

                    <Controller
                      as={Input}
                      label="Applicant Onboarding URL"
                      name="agency.candidate_onboarding_url"
                      control={control}
                      error={errors.agency?.candidate_onboarding_url}
                      rules={{
                        required: true,
                        validate: {
                          valid: (value) =>
                            value
                              ? UrlRegex.test(value) ||
                                "That format doesn't look right. Make sure there aren't any typos"
                              : true,
                        },
                      }}
                      defaultValue=""
                    />
                  </Row>

                  <Row>
                    <Controller
                      as={Input}
                      label="Additional booking URL"
                      name="agency.additional_booking_url"
                      control={control}
                      error={errors.agency?.additional_booking_url}
                      rules={{
                        validate: {
                          valid: (value) =>
                            value
                              ? UrlRegex.test(value) ||
                                "That format doesn't look right. Make sure there aren't any typos"
                              : true,
                        },
                      }}
                      defaultValue=""
                    />

                    <Controller
                      as={Input}
                      label="Additional booking URL for API sponsored sessions"
                      name="agency.additional_booking_url_sponsored"
                      control={control}
                      error={errors.agency?.additional_booking_url_sponsored}
                      rules={{
                        validate: {
                          valid: (value) =>
                            value
                              ? UrlRegex.test(value) ||
                                "That format doesn't look right. Make sure there aren't any typos"
                              : true,
                        },
                      }}
                      defaultValue=""
                    />
                  </Row>

                  <Options>
                    <Controller
                      name="agency.show_interview_links"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="Show session link to agency admin"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.additional_info"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label={`"Session details" section in agency booking flow`}
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.r_and_d"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="R&#38;D"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.admin_booking"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="Allow admin booking"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.enable_conversation_intelligence"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="Conversation intelligence"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.priority_account"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="Priority account"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.use_new_applicant_availability_flow"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="New availability flow"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.has_ab_testing"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="A/B testing"
                          onChange={onChange}
                          value={allowDirectBooking ? false : value ?? false}
                          name={name}
                          disabled={!newApplicantAvailabilityFlow || allowDirectBooking}
                        />
                      )}
                      control={control}
                    />
                  </Options>

                  {isB2C && <AgencyPriceTiers control={control} errors={errors} />}

                  <ValidEmailDomains control={control} errors={errors} />

                  <Controller
                    name="agency.ignored_emails"
                    control={control}
                    render={({ onChange, value }) => (
                      <IgnoredEmails emails={value} onChange={onChange} />
                    )}
                  />

                  {mode === AgencyFormType.Edit && (
                    <InternalExperts control={control} errors={errors} />
                  )}
                </Container>
              )}

              {isAvaAgency && (
                <Container>
                  <Title>Ava Settings</Title>

                  <Row>
                    <Controller
                      as={Input}
                      label="Survey URL"
                      name="agency.survey_url_ava"
                      control={control}
                      error={errors.agency?.survey_url_ava}
                      rules={{
                        required: true,
                        validate: {
                          valid: (value) =>
                            value
                              ? UrlRegex.test(value) ||
                                "That format doesn't look right. Make sure there aren't any typos"
                              : true,
                        },
                      }}
                      defaultValue=""
                    />
                  </Row>

                  <Options>
                    <Controller
                      name="agency.disable_copy_paste_answer"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="Disable copy-paste action in the answer textbox"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />

                    <Controller
                      name="agency.whitelisted"
                      render={({ onChange, value, name }) => (
                        <CheckBox
                          label="Whitelisted"
                          onChange={onChange}
                          value={value ?? false}
                          name={name}
                        />
                      )}
                      control={control}
                    />
                  </Options>

                  {mode === AgencyFormType.Edit && (
                    <AgencyManagers control={control} errors={errors} />
                  )}

                  {showAgencyPositions && (
                    <Controller
                      name="agency.agency_positions"
                      control={control}
                      render={({ onChange, value }) => (
                        <AgencyPositions positions={value} onChange={onChange} />
                      )}
                    />
                  )}
                </Container>
              )}

              {mode === AgencyFormType.Edit && (
                <AgencyAdmins control={control} errors={errors} />
              )}

              <ButtonStyled disabled={!isDirty || !isValid} title="Save" />
            </>
          )}
        </Form>
      </>
    </Layout>
  )
}

export default AgencyForm
