import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import ArrowImage from 'src/static/icArrowBack.png'
import { useMediaQuery } from 'react-responsive'
import { css } from '@emotion/core'
import { BackButton, NavButton } from '../elements/styled-components'
import BallsAnimation from '../interview/BallsAnimation'

const LineContainer = styled.div<{ showBack?: boolean }>`
  display: flex;
  margin-top: auto;
  width: 100%;
  justify-content: ${({ showBack }) => (showBack ? 'space-between' : 'flex-end')};
  align-items: center;

  @media screen and (max-width: 1100px) {
    justify-content: space-between;
  }

  ${isMobileOnly &&
  css`
    width: auto;
    position: sticky;
    bottom: 0;
    background: #fff;
  `}
`

const BackButtonStyled = styled(BackButton)`
  margin-bottom: 0;

  ${isMobileOnly &&
  css`
    margin-right: 15px;
  `}
`

const NavButtonStyled = styled(NavButton)`
  ${isMobileOnly &&
  css`
    margin-bottom: 16px;
    margin-top: 8px;
  `}
`

const LoadingContainer = styled.div`
  display: flex;
`

const LoadingText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #000;
  margin: 0 8px;
`

interface IProps {
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  showBackButton?: boolean
  onNext?: () => void
  onBack?: () => void
}

const BottomComponent = ({
  disabled,
  loading,
  loadingText,
  showBackButton,
  onNext,
  onBack,
}: IProps) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1100px)' }) && !isMobileOnly

  const showNextButton = isMobileOnly || (!isMobileOnly && !loading)
  const showLoading = loading && !isMobileOnly

  return (
    <LineContainer showBack={showBackButton}>
      {(isSmallScreen || showBackButton) && (
        <BackButtonStyled src={ArrowImage} onClick={onBack} />
      )}
      {showNextButton && (
        <NavButtonStyled onClick={onNext} disabled={disabled} hideIcon={isMobileOnly}>
          {loading && isMobileOnly ? <BallsAnimation /> : 'Next'}
        </NavButtonStyled>
      )}
      {showLoading && (
        <LoadingContainer>
          <BallsAnimation />
          <LoadingText>{loadingText}</LoadingText>
        </LoadingContainer>
      )}
    </LineContainer>
  )
}

BottomComponent.defaultProps = {
  disabled: false,
  loading: false,
  showBackButton: false,
  loadingText: 'Loading',
  onNext: () => {},
  onBack: () => {},
}

export default BottomComponent
