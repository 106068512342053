import { put, takeEvery, call, take } from 'typed-redux-saga'

import { avaSaveAnswer } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { avaGetInterviewAction, avaGetInterviewActionSuccessType } from './getInterview'

export interface IAvaSaveAnswerAction {
  answer: string
  interviewToken: string
  questionId: string
}

const {
  action: avaSaveAnswerAction,
  actionFailure: avaSaveAnswerActionFailure,
  actionSuccess: avaSaveAnswerActionSuccess,
  actionSuccessClear: avaSaveAnswerActionSuccessClear,
  actionFailureClear: avaSaveAnswerActionFailureClear,
  actionType: avaSaveAnswerActionType,
  actionTypeFailure: avaSaveAnswerActionFailureType,
  actionTypeSuccess: avaSaveAnswerActionSuccessType,
  reducer: avaSaveAnswerRequestReducer,
  selectors: avaSaveAnswerSelectors,
} = reduxRequestFactory<IAvaSaveAnswerAction>('avaSaveAnswer', 'requests')

export {
  avaSaveAnswerAction,
  avaSaveAnswerActionFailure,
  avaSaveAnswerActionSuccess,
  avaSaveAnswerActionSuccessClear,
  avaSaveAnswerActionFailureClear,
  avaSaveAnswerActionType,
  avaSaveAnswerActionFailureType,
  avaSaveAnswerActionSuccessType,
  avaSaveAnswerRequestReducer,
  avaSaveAnswerSelectors,
}

function* avaSaveAnswerSaga(action: ISagaAction<IAvaSaveAnswerAction>) {
  try {
    const { answer: text, interviewToken, questionId: question_id } = action.payload
    yield* call(avaSaveAnswer, interviewToken, {
      answer: { text },
      question_id,
    })

    yield put(avaGetInterviewAction({ interviewToken }))
    yield take(avaGetInterviewActionSuccessType)

    yield put(avaSaveAnswerActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaSaveAnswerActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaSaveAnswer() {
  yield takeEvery(avaSaveAnswerActionType, avaSaveAnswerSaga)
}
