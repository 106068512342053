/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useComunicationInsights, {
  InsightsTab,
} from 'src/ava/services/useCommunicationInsights'
import { avaInterviewSelectors } from 'src/redux'
import SvgDiscloserSmall from 'src/static/components/SvgDiscloserSmall'
import { avaDataSelectors, updateAvaDataAction } from 'src/redux/data/ava/data'
import SvgFollowUp from 'src/static/components/SvgFollowUp'
import SvgLamp from 'src/static/components/SvgLamp'
import useBranding from 'src/utils/useBranding'
import { CheckedLabel } from '../elements/styled-components'
import CircleProgressSmall from './CircleProgressSmall'

const QuestionDetailsContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
`

const QuestionDetailsBackground = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.3s;
  background: #3b424f;
`

const QuestionDataContainer = styled.div<{ visible: boolean }>`
  padding: 18px 16px;
  width: 100%;
  display: flex;
  margin-top: auto;
  flex-direction: column;
  border-radius: 24px 24px 0px 0px;
  background: #fff;
  transform: ${({ visible }) => (visible ? 'none' : 'translateY(100%)')};
  transition: all 0.3s;
  max-height: 100%;
  overflow-y: auto;
  z-index: 100;
`

const CloseButton = styled.div`
  color: ${({ theme }: any) => {
    const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return agencyColor
  }};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: auto;
`

const DetailsTitle = styled.div`
  color: #222b45;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

const LineContainer = styled.div<{
  useCursor?: boolean
  bottom?: number
  column?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ column }) => (column ? 'start' : 'center')};
  cursor: ${({ useCursor }) => (useCursor ? 'pointer' : 'default')};
  margin-bottom: ${({ bottom }) => bottom ?? 0}px;

  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  width: 100%;
`

const TabsContainer = styled.div`
  display: flex;
  margin-top: 16px;
`

const AnswerContainer = styled.div<{ collapsed?: boolean }>`
  padding: 16px;
  max-height: ${({ collapsed }) => (collapsed ? 48 : 172)}px;
  border-radius: 8px;
  background: #f5f6f8;
  margin-top: 24px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const DiscoloserContainer = styled.div<{ collapsed?: boolean }>`
  display: flex;
  transform: rotate(${({ collapsed }) => (collapsed ? 0 : 180)}deg);
  transition: transform 0.2s;
`

const AnswerTitle = styled.div`
  color: #222b45;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const AnswerText = styled.div`
  color: #222b45;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
  overflow-x: auto;
`

const DescTitle = styled.div`
  color: #222b45;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
`

const DescText = styled.div`
  color: #222b45;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: auto;
`

const Separator = styled.div`
  height: 0px;
  border-bottom: 1px solid #e1e3ea;
  width: 100%;
  margin: 24px 0;
`

const QuestionText = styled.div`
  color: #222b45;
  font-size: 14px;
  line-height: 20px;
  height: auto;
`

const QuestionIndexLabel = styled.div`
  color: #a9a9a9;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
  text-align: start;
`

const getTransparentColor = (color: string) => {
  return `${color}1E`
}

const HighlightedTextInfoContainer = styled.div<{ color: string; empty?: boolean }>`
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${({ empty }) => (empty ? '#e3e5ea' : 'transparent')};
  background: ${({ empty, color }) => (empty ? '#fff' : getTransparentColor(color))};
  max-width: 360px;
  display: flex;
  align-items: center;
  align-self: end;

  @media (max-width: 1440px) {
    margin-top: 24px;
    width: 100%;
    max-width: 100%;
  }
`

const HighlightedTextInfo = styled.div<{ color: string; empty?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ color, empty }) => (empty ? '#222B45' : color)};
  margin-left: ${({ empty }) => (empty ? '8' : '0')}px;
  flex: 1;
`

const TabsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`

const Tabs = [InsightsTab.Positive, InsightsTab.Negative, InsightsTab.Hesitation]

interface IProps {
  visible: boolean
}

const QuestionDetailsPopup = ({ visible }: IProps) => {
  const dispatch = useDispatch()
  const { agencyColor } = useBranding()

  const avaData = useSelector(avaDataSelectors.data)
  const interview = useSelector(avaInterviewSelectors.data)
  const questions = interview?.questions?.map((q) => [q, ...(q.follow_up || [])]).flat()
  const answerRef = useRef<HTMLDivElement>(null)

  const {
    getInterviewQuestionIndexText,
    getQuestionHighLightTextData,
    getTabColor,
  } = useComunicationInsights()

  const [answerCollapsed, setAnswerCollapsed] = useState(false)
  const [highlightedText, setHighlightedText] = useState(null)
  const [noHighlightedText, setNoHighlightedText] = useState(false)
  const [highlightedTextInfo, sethighlightedTextInfo] = useState('')
  const [activeTab, setActiveTab] = useState(InsightsTab.Positive)

  useEffect(() => {
    if (avaData.selectedQuestion) {
      setActiveTab(InsightsTab.Positive)
      setAnswerCollapsed(false)
    }
  }, [avaData.selectedQuestion])

  const dropSelectedQuestion = () => {
    dispatch(updateAvaDataAction({ selectedQuestion: null }))
  }

  useEffect(() => {
    const firstHighlightedElement = document.getElementById('first-highlighted')
    if (firstHighlightedElement) {
      firstHighlightedElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    }
  }, [highlightedText])

  useEffect(() => {
    const textData = getQuestionHighLightTextData(avaData.selectedQuestion, activeTab)
    if (!textData) return
    if (textData.count !== 0) {
      answerRef.current.scrollTo({ top: 0 })
    }
    setHighlightedText(textData.text)
    if (textData.count > 0) {
      setNoHighlightedText(false)
      switch (activeTab) {
        case InsightsTab.Positive:
          sethighlightedTextInfo(
            `${textData.count} positive sentiment${
              textData.count > 1 ? 's' : ''
            } highlighted.`
          )
          break
        case InsightsTab.Negative:
          sethighlightedTextInfo(
            `${textData.count} negative sentiment${
              textData.count > 1 ? 's' : ''
            } highlighted.`
          )
          break
        case InsightsTab.Hesitation:
          sethighlightedTextInfo(
            `${textData.count} moment${
              textData.count > 1 ? 's' : ''
            } of hesitation observed.`
          )
          break
        default:
          sethighlightedTextInfo('')
      }
    } else {
      setNoHighlightedText(true)
      switch (activeTab) {
        case InsightsTab.Positive:
          sethighlightedTextInfo(
            `No positive sentiment observed. Being more positive will amplify your impact.`
          )
          break
        case InsightsTab.Negative:
          sethighlightedTextInfo(
            `No negative sentiment observed. Navigating without any negativity is an art, and you've mastered it!`
          )
          break
        case InsightsTab.Hesitation:
          sethighlightedTextInfo(
            'No hesitation words or phrases observed. Straightforward and assertive - your message is free of doubts!'
          )
          break
        default:
          sethighlightedTextInfo('')
      }
    }
  }, [avaData.selectedQuestion, activeTab, getQuestionHighLightTextData])

  return (
    <QuestionDetailsContainer visible={visible}>
      <QuestionDetailsBackground visible={visible} />
      <QuestionDataContainer visible={visible}>
        <LineContainer bottom={34}>
          <CloseButton onClick={dropSelectedQuestion}>Close</CloseButton>
        </LineContainer>
        <QuestionIndexLabel>
          {!!avaData.selectedQuestion?.parent_id && <SvgFollowUp fill="#A9A9A9" />}
          {getInterviewQuestionIndexText(avaData.selectedQuestion, questions)}
        </QuestionIndexLabel>
        <QuestionText>{avaData.selectedQuestion?.text}</QuestionText>
        <Separator />
        <LineContainer column>
          <LineContainer>
            <TabsBlock>
              <DetailsTitle>Highlight your communication tone:</DetailsTitle>
              <TabsContainer>
                {Tabs.map((tab) => (
                  <CheckedLabel
                    key={tab}
                    checked={tab === activeTab}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </CheckedLabel>
                ))}
              </TabsContainer>
            </TabsBlock>
            <CircleProgressSmall
              value={avaData.selectedQuestion?.score ?? 1}
              maxValue={10}
              useHint
              hintText="Your score assessment"
            />
          </LineContainer>
          <HighlightedTextInfoContainer
            empty={noHighlightedText}
            color={getTabColor(activeTab)}
          >
            {noHighlightedText && <SvgLamp fill={agencyColor} />}
            <HighlightedTextInfo empty={noHighlightedText} color={getTabColor(activeTab)}>
              {highlightedTextInfo}
            </HighlightedTextInfo>
          </HighlightedTextInfoContainer>
        </LineContainer>
        <AnswerContainer collapsed={answerCollapsed}>
          <LineContainer useCursor onClick={() => setAnswerCollapsed((v) => !v)}>
            <AnswerTitle>your answer</AnswerTitle>
            <DiscoloserContainer collapsed={answerCollapsed}>
              <SvgDiscloserSmall fill="#222B45" />
            </DiscoloserContainer>
          </LineContainer>
          <AnswerText ref={answerRef}>
            {highlightedText?.map((hItem, index) => (
              <span key={index}>{hItem}</span>
            ))}
          </AnswerText>
        </AnswerContainer>
        <DescTitle>Key takeaways</DescTitle>
        <DescText>{avaData.selectedQuestion?.recommendations}</DescText>
      </QuestionDataContainer>
    </QuestionDetailsContainer>
  )
}

export default QuestionDetailsPopup
