import styled from '@emotion/styled'
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  avaQuestionIndexSelectors,
  updateAvaQuestionsIndex,
} from 'src/redux/data/ava/questionIndex'
import {
  avaGetInterviewAction,
  avaInterviewSelectors,
  avaSettingsSelectors,
  updateAvaSettingsAction,
  updateAvaStepAction,
} from '../../redux'
import Header from '../components/Header'
import AIQuestionComponent from '../components/interview/AIQuestionComponent'
import { AvaStep, InterviewPart } from '../models'
import RotatePhoneComponent from '../components/elements/RotatePhoneComponent'
import SettingsBar from '../components/elements/SettingsBar'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    height: auto;
  }

  ${isMobileOnly &&
  css`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #f6f4fd;
    min-height: 100%;
    min-width: 100%;
  `}
`

function InterviewContainer() {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const avaSettings = useSelector(avaSettingsSelectors.data)

  const interview = useSelector(avaInterviewSelectors.data)
  const questions = interview?.questions.map((q) => [q, ...(q.follow_up || [])]).flat()
  const questionIndex = useSelector(avaQuestionIndexSelectors.data)

  const handleNext = () => {
    if (!questionIndex.questionId) return

    const index = questions.findIndex((q) => q.id === questionIndex.questionId) + 1

    if (index >= questions.length) {
      dispatch(updateAvaStepAction(AvaStep.Report))
    } else {
      const nextQuestion = questions[index]
      dispatch(
        updateAvaQuestionsIndex({
          ...questionIndex,
          questionId: nextQuestion.id,
        })
      )
    }
  }

  const handleBack = () => {
    const index = questions.findIndex((q) => q.id === questionIndex.questionId) - 1
    if (index < 0) return

    const prevQuestion = questions[index]
    dispatch(
      updateAvaQuestionsIndex({
        ...questionIndex,
        questionId: prevQuestion.id,
      })
    )
  }

  useEffect(() => {
    if (!questionIndex.questionId && !!questions.length) {
      const unansweredQuestion = questions.find((q) => !q.answer)
      dispatch(
        updateAvaQuestionsIndex({
          ...questionIndex,
          questionCount: questions.length,
          questionId: unansweredQuestion.id,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions])

  useEffect(() => {
    if (interviewToken) {
      dispatch(avaGetInterviewAction({ interviewToken }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const microphones = devices.filter((d) => d.kind === 'audioinput')
      if (microphones.length > 0) {
        dispatch(
          updateAvaSettingsAction({
            ...avaSettings,
            microphoneId: microphones[0].deviceId,
          })
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Header
        showInterviewPart
        showSettingsButton
        showBackButton={false}
        interviewPart={InterviewPart.Interview}
        onBack={handleBack}
      />
      <AIQuestionComponent goNext={handleNext} goBack={handleBack} canGoBack={false} />
      {isMobileOnly && <RotatePhoneComponent />}
      <SettingsBar />
      <audio id="ava-audio" />
    </Layout>
  )
}

export default InterviewContainer
