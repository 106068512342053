/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  avaIntroStepSelectors,
  updateAvaIntroStepAction,
  updateAvaStepAction,
} from 'src/redux/data/ava/step'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  avaQuestionIndexSelectors,
  updateAvaQuestionsIndex,
} from 'src/redux/data/ava/questionIndex'
import { avaInterviewSelectors, avaSaveInfoAction } from '../../redux'
import UserNameComponent from '../components/intro/UserNameComponent'
import PositionDetailsComponent from '../components/intro/PositionDetailsComponent'
import Header from '../components/Header'
import PositionComponent from '../components/intro/PositionComponent'
import PracticeForCompanyComponent from '../components/intro/PracticeForCompanyComponent'
import InfoComponent from '../components/intro/InfoComponent'
import QuestionComponent from '../components/intro/QuestionComponent'
import RoleComponent from '../components/intro/RoleComponent'
import { SpeechTexts } from '../speechService'
import GettingQuestionsComponent from '../components/intro/GettingQuestionsComponent'
import { AvaStep, InterviewPart } from '../models'
import RotatePhoneComponent from '../components/elements/RotatePhoneComponent'
// import QuestionsReadyComponent from '../components/intro/QuestionsReadyComponent'
import SettingsBar from '../components/elements/SettingsBar'
import ManagerComponent from '../components/intro/ManagerComponent'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    height: 100%;
  }

  ${isMobileOnly &&
  css`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #f6f4fd;
    min-height: 100%;
    min-width: 100%;
  `}
`

export enum IntroStep {
  UserName = 'hello',
  Position = 'position',
  Role = 'role',
  IsManager = 'isManager',
  IsPracticeForCompany = 'isPracticeForCompany',
  PositionDetails = 'positionDetails',
  Experience = 'experience',
  IKnowYouBetter = 'iknowyoubetter',
  GettingQuestions = 'gettingQuestions',
}

interface IStepData {
  step: IntroStep
  nextStep?: IntroStep
}

const StepsData: IStepData[] = [
  {
    step: IntroStep.UserName,
    nextStep: IntroStep.Position,
  },
  {
    step: IntroStep.Position,
    nextStep: IntroStep.Role,
  },
  {
    step: IntroStep.Role,
    nextStep: IntroStep.IsPracticeForCompany,
  },
  {
    step: IntroStep.IsManager,
    nextStep: IntroStep.IsPracticeForCompany,
  },
  {
    step: IntroStep.IsPracticeForCompany,
    nextStep: IntroStep.Experience,
  },
  {
    step: IntroStep.PositionDetails,
    nextStep: IntroStep.Experience,
  },
  {
    step: IntroStep.Experience,
    nextStep: IntroStep.GettingQuestions,
  },
  {
    step: IntroStep.GettingQuestions,
    nextStep: IntroStep.IKnowYouBetter,
  },
  {
    step: IntroStep.IKnowYouBetter,
    nextStep: null,
  },
]

function IntroContainer() {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()

  const interview = useSelector(avaInterviewSelectors.data)

  const step = useSelector(avaIntroStepSelectors.data)
  const questionIndex = useSelector(avaQuestionIndexSelectors.data)
  const [backMode, setBackMode] = useState(false)

  const goNextStep = (currStep: IStepData) => {
    setBackMode(false)
    dispatch(updateAvaIntroStepAction(currStep.nextStep))
    dispatch(
      updateAvaQuestionsIndex({
        ...questionIndex,
        questionNumberText: '',
        questionNumber: questionIndex.questionNumber + 1,
      })
    )
  }

  const goToOnboarding = () => {
    dispatch(updateAvaStepAction(AvaStep.Onboarding))
  }

  const goPrevStep = (currStep: IStepData) => {
    setBackMode(true)
    switch (currStep.step) {
      case IntroStep.UserName:
        goToOnboarding()
        break
      case IntroStep.IsManager:
        dispatch(
          updateAvaQuestionsIndex({
            ...questionIndex,
            questionNumberText: '',
          })
        )
        dispatch(updateAvaIntroStepAction(IntroStep.Role))
        break
      case IntroStep.PositionDetails:
        dispatch(
          updateAvaQuestionsIndex({
            ...questionIndex,
            questionNumberText: '',
          })
        )
        dispatch(updateAvaIntroStepAction(IntroStep.IsPracticeForCompany))
        break
      case IntroStep.IKnowYouBetter:
        dispatch(
          updateAvaQuestionsIndex({
            ...questionIndex,
            questionNumberText: '',
            questionNumber: 4,
          })
        )
        dispatch(updateAvaIntroStepAction(IntroStep.Experience))
        break
      default:
        dispatch(
          updateAvaIntroStepAction(
            StepsData.find((s) => s.nextStep === currStep.step).step
          )
        )
        dispatch(
          updateAvaQuestionsIndex({
            ...questionIndex,
            questionNumber: questionIndex.questionNumber - 1,
          })
        )
    }
  }

  useEffect(() => {
    dispatch(
      updateAvaQuestionsIndex({
        ...questionIndex,
        questionNumber: 0,
        questionCount: 5,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showBackButton =
    step !== IntroStep.IKnowYouBetter && step !== IntroStep.GettingQuestions

  const renderStep = (currentStep: IntroStep) => {
    const currentStepData = StepsData.find((s) => s.step === currentStep)
    switch (currentStep) {
      case IntroStep.UserName:
        return (
          <UserNameComponent
            backMode={backMode}
            goNext={(candidate) => {
              dispatch(
                avaSaveInfoAction({
                  interviewToken,
                  params: { to_intro: true },
                  candidate,
                })
              )
              goNextStep(currentStepData)
            }}
            goBack={() => goPrevStep(currentStepData)}
          />
        )
      case IntroStep.Position:
        return (
          <PositionComponent
            backMode={backMode}
            goNext={(position) => {
              dispatch(avaSaveInfoAction({ interviewToken, position: { position } }))
              goNextStep(currentStepData)
            }}
            goBack={() => goPrevStep(currentStepData)}
          />
        )
      case IntroStep.Role:
        return (
          <RoleComponent
            backMode={backMode}
            goNext={(role) => {
              dispatch(
                avaSaveInfoAction({
                  interviewToken,
                  position: { role: role.label },
                })
              )
              if (role.is_manager) {
                setBackMode(false)
                dispatch(updateAvaIntroStepAction(IntroStep.IsManager))
                dispatch(
                  updateAvaQuestionsIndex({
                    ...questionIndex,
                    questionNumberText: '(Additional info)',
                  })
                )
              } else {
                goNextStep(currentStepData)
              }
            }}
            goBack={() => goPrevStep(currentStepData)}
          />
        )
      case IntroStep.IsManager:
        return (
          <ManagerComponent
            backMode={backMode}
            goNext={(isManager) => {
              dispatch(
                avaSaveInfoAction({ interviewToken, position: { is_manager: isManager } })
              )
              goNextStep(currentStepData)
            }}
            goBack={() => goPrevStep(currentStepData)}
          />
        )
      case IntroStep.IsPracticeForCompany:
        return (
          <PracticeForCompanyComponent
            backMode={backMode}
            goNext={(res) => {
              if (res) {
                setBackMode(false)
                dispatch(
                  avaSaveInfoAction({
                    interviewToken,
                    position: { is_specific_company: true },
                  })
                )
                dispatch(updateAvaIntroStepAction(IntroStep.PositionDetails))
                dispatch(
                  updateAvaQuestionsIndex({
                    ...questionIndex,
                    questionNumberText: '(Additional info)',
                  })
                )
              } else {
                dispatch(
                  avaSaveInfoAction({
                    interviewToken,
                    position: { is_specific_company: false },
                  })
                )
                goNextStep(currentStepData)
              }
            }}
            goBack={() => goPrevStep(currentStepData)}
          />
        )
      case IntroStep.PositionDetails:
        return (
          <PositionDetailsComponent
            backMode={backMode}
            goNext={({ company, qualifications, responsibilities }) => {
              dispatch(
                avaSaveInfoAction({
                  interviewToken,
                  position: {
                    company,
                    qualifications,
                    responsibilities,
                  },
                })
              )
              goNextStep(currentStepData)
            }}
            goBack={() => goPrevStep(currentStepData)}
          />
        )
      case IntroStep.Experience:
        return (
          <QuestionComponent
            backMode={backMode}
            text={SpeechTexts.ProfessionalBackground}
            subTitle={SpeechTexts.BetterUnderstandNeeds}
            title="My professional background"
            placeholder="You can copy and paste this information from your CV or LinkedIn profile"
            canGoBack
            noUrl
            optional
            value={interview.candidate?.experience}
            goNext={(experience) => {
              dispatch(
                avaSaveInfoAction({
                  interviewToken,
                  params: { to_interview: true },
                  candidate: { experience },
                })
              )
              goNextStep(currentStepData)
            }}
            goBack={() => goPrevStep(currentStepData)}
          />
        )
      case IntroStep.GettingQuestions:
        return (
          <GettingQuestionsComponent
            backMode={backMode}
            goNext={() => goNextStep(currentStepData)}
          />
        )
      case IntroStep.IKnowYouBetter:
        return (
          <InfoComponent
            backMode={backMode}
            title={SpeechTexts.IHaveEverything}
            info={SpeechTexts.IAskYouQuestions}
            goNext={() => {
              dispatch(updateAvaQuestionsIndex({ ...questionIndex, questionNumber: 0 }))
              dispatch(updateAvaStepAction(AvaStep.Interview))
            }}
          />
        )
      // case IntroStep.QuestionsAreReady:
      //   return (
      //     <QuestionsReadyComponent
      //       backMode={backMode}
      //       goNext={() => {
      //         dispatch(updateAvaQuestionsIndex({ ...questionIndex, questionNumber: 0 }))
      //         dispatch(updateAvaStepAction(AvaStep.Interview))
      //       }}
      //       goBack={() => dispatch(updateAvaIntroStepAction(IntroStep.IKnowYouBetter))}
      //     />
      //   )
      default:
        return 'Smth went wrong...'
    }
  }

  return (
    <Layout>
      <Header
        showInterviewPart
        showSettingsButton
        showBackButton={showBackButton}
        interviewPart={InterviewPart.Intro}
        onBack={() => goPrevStep(StepsData.find((s) => s.step === step))}
      />
      {renderStep(step)}
      {isMobileOnly && <RotatePhoneComponent />}
      <SettingsBar />
      <audio id="ava-audio" />
    </Layout>
  )
}

export default IntroContainer
