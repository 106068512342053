import { isMobileOnly } from 'react-device-detect'
import httpClient from 'src/httpClient'

// For quick fix enable at Router.tsx and use "/speechdata" endpoint

export const SpeechTexts = {
  LoveToKnowYou: `I'd love to get to know you better.`,
  NameAndEmail: `What’s your name and email address?`,
  WhatField: 'What field do you want to practice for?',
  WhatRole: 'What role are you preparing for?',
  ManagerRole: 'Does this role include managing people directly?',
  PracticeForCompany: 'Do you have a specific company in mind?',
  WillHelpPersonolize: 'This helps me personalize your questions and feedback better.',
  MoreDataMoreAccurate: 'The more data I have, the better.',
  WhatCompany: 'What company are you applying to?',
  BetterUnderstandNeeds: 'Now that I better understand your needs,',
  ProfessionalBackground: 'can you tell me more about your professional background?',
  IHaveEverything: 'Great! I have everything I need.',
  GettingQuestions:
    'I’m getting your personalized questions ready, this should only take a moment.',
  IAskYouQuestions: `I'll ask you five questions that are tailored to your background and goals. You can either reply by speaking naturally and I'll transcribe your response, or you can type your answers directly.`,
  QuestionsAreReady: 'Your practice questions are ready!',
  IfYouDontLikeQuestion:
    'If you don’t like a question, you can always ask for an alternative one later.',

  MyNameIsAva: `Hi there!\nMy name is Ava.`,
  IamHereToHelpYou: isMobileOnly
    ? `I'm here to help you build confidence, sharpen your interview skills, and ace your next job interview.`
    : `I'm here to help you build confidence, sharpen your interview skills,\nand ace your next job interview.`,
  AreYouReadyToStart: `Are you ready to get started?`,
  WhatYouCanExpect: 'Here’s what you can expect from our session.',
  ToKnowYourGoals: `First, I'm going to get to know you and your career goals. The more I know, the more accurate and beneficial your experience will be.`,
  NextIAskYou: `Next, I'll ask you five questions tailored to your background, field and the role you’re interested in.`,
  LastlyIProvideReport: isMobileOnly
    ? `Lastly, I’ll provide you with a comprehensive performance report, complete with actionable tips and personalized insights and recommendations. Sound good?`
    : `Lastly, I’ll provide you with a comprehensive performance report, complete with actionable tips and personalized insights and recommendations.\n\n\nSound good?`,
  ReportIsReady: `Your report is ready!`,
  SitBackWhileIReview: `Alright, sit back while I review your answers...`,
}

const speechData = [
  { text: SpeechTexts.LoveToKnowYou, file: '/static/speech/loveToKnowYou.txt' },
  { text: SpeechTexts.NameAndEmail, file: '/static/speech/nameAndEmail.txt' },
  { text: SpeechTexts.WhatField, file: '/static/speech/whatField.txt' },
  { text: SpeechTexts.WhatRole, file: '/static/speech/whatRole.txt' },
  { text: SpeechTexts.ManagerRole, file: '/static/speech/managerRole.txt' },
  { text: SpeechTexts.PracticeForCompany, file: '/static/speech/practiceForCompany.txt' },
  {
    text: SpeechTexts.WillHelpPersonolize,
    file: '/static/speech/willHelpPersonolize.txt',
  },
  {
    text: SpeechTexts.MoreDataMoreAccurate,
    file: '/static/speech/moreDataMoreAccurate.txt',
  },
  { text: SpeechTexts.WhatCompany, file: '/static/speech/whatCompany.txt' },
  {
    text: SpeechTexts.BetterUnderstandNeeds,
    file: '/static/speech/betterUnderstandNeeds.txt',
  },
  {
    text: SpeechTexts.ProfessionalBackground,
    file: '/static/speech/professionalBackground.txt',
  },
  { text: SpeechTexts.IHaveEverything, file: '/static/speech/iHaveEverything.txt' },
  { text: SpeechTexts.GettingQuestions, file: '/static/speech/gettingQuestions.txt' },
  { text: SpeechTexts.IAskYouQuestions, file: '/static/speech/iAskYouQuestions.txt' },
  { text: SpeechTexts.QuestionsAreReady, file: '/static/speech/questionsAreReady.txt' },
  {
    text: SpeechTexts.IfYouDontLikeQuestion,
    file: '/static/speech/ifYouDontLikeQuestion.txt',
  },

  { text: SpeechTexts.MyNameIsAva, file: '/static/speech/myNameIsAva.txt' },
  { text: SpeechTexts.IamHereToHelpYou, file: '/static/speech/iAmHereToHelpYou.txt' },
  { text: SpeechTexts.AreYouReadyToStart, file: '/static/speech/areYouReadyToStart.txt' },
  { text: SpeechTexts.WhatYouCanExpect, file: '/static/speech/whatYouCanExpect.txt' },
  { text: SpeechTexts.ToKnowYourGoals, file: '/static/speech/toKnowYourGoals.txt' },
  { text: SpeechTexts.NextIAskYou, file: '/static/speech/nextIAskYou.txt' },
  {
    text: SpeechTexts.LastlyIProvideReport,
    file: '/static/speech/lastlyIProvideReport.txt',
  },
  { text: SpeechTexts.ReportIsReady, file: '/static/speech/reportIsReady.txt' },
  {
    text: SpeechTexts.SitBackWhileIReview,
    file: '/static/speech/sitBackWhileIReview.txt',
  },
]

export const getSpeechData = (text: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const data = speechData.find((s) => s.text === text)
    if (!data) {
      reject()
    } else {
      fetch(data.file)
        .then((res) =>
          res
            .text()
            .then((stringData) => resolve(stringData))
            .catch((err) => reject(err))
        )
        .catch((err) => reject(err))
    }
  })
}

export const loadSpeechData = (text: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const txt = text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    httpClient
      .post(`/ava/synthesize_voice`, { text: txt }, { responseType: 'arraybuffer' })
      .then((response) => {
        const binaryData = response.data
        const base64ImageString = Buffer.from(binaryData, 'binary').toString('base64')
        resolve(base64ImageString)
      })
      .catch((err) => reject(err))
  })
}
