import { IAvaQuestion } from 'src/api'
import { findAll } from 'highlight-words-core'
import { useSelector } from 'react-redux'
import { conversationSelectors, systemSettingsSelectors } from 'src/redux'
import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'
import { WarningPhrasesMap } from '../avaConstants'
import HighLinghtedTextTip from '../components/report/HighLinghtedTextTip'

export enum InsightsTab {
  Positive = 'Positive',
  Negative = 'Negative',
  Hesitation = 'Hesitation',
}

interface IActiveText {
  questionId: string
  text: string
  index: number
}

const DefActiveText: IActiveText = {
  questionId: '',
  text: '',
  index: -1,
}

const HighLinghtedTextContainer = styled.span`
  position: relative;
  cursor: pointer;
`

const HighLinghtedText = styled.span<{
  color: string
}>`
  color: ${({ color }) => color};
  font-weight: 500;
  font-style: italic;
`

const SpecialCharArray = [
  { char: /\*/g, replacement: '\\' },
  { char: /\+/g, replacement: '\\+' },
  { char: /\*/g, replacement: '\\*' },
  { char: /\?/g, replacement: '\\?' },
  { char: /\^/g, replacement: '\\^' },
  { char: /\$/g, replacement: '\\$' },
  { char: /\(/g, replacement: '\\(' },
  { char: /\)/g, replacement: '\\)' },
  { char: /\[/g, replacement: '\\[' },
  { char: /\]/g, replacement: '\\]' },
  { char: /\{/g, replacement: '\\{' },
  { char: /\}/g, replacement: '\\}' },
  { char: /\|/g, replacement: '\\|}' },
]

const useComunicationInsights = () => {
  const conversation = useSelector(conversationSelectors.data)
  const systemSettings = useSelector(systemSettingsSelectors.data)

  const [activeText, setActiveText] = useState<IActiveText>(DefActiveText)

  const getTabColor = (tab: InsightsTab) => {
    switch (tab) {
      case InsightsTab.Positive:
        return '#24AB85'
      case InsightsTab.Negative:
        return '#EF327F'
      case InsightsTab.Hesitation:
        return '#FBBD08'
      default:
        return 'transparent'
    }
  }

  const getInterviewQuestionIndexText = (
    question: IAvaQuestion,
    questions: IAvaQuestion[]
  ) => {
    if (!question) return ''
    const currentQuestionIndex = questions.findIndex((q) => q.id === question.id)
    return `${currentQuestionIndex + 1}/${questions.length} - ${
      question.question_type
    } Question${question.parent_id ? ' - follow up' : ''}`
  }

  const getPhraseTip = (phrase: string) => {
    const map = WarningPhrasesMap.find((wp) =>
      wp.pharses.some((pr) => pr.toLocaleLowerCase() === phrase.toLocaleLowerCase())
    )

    if (map) return map.tip
    return null
  }

  const protectString = (str: string) => {
    let result = str
    SpecialCharArray.forEach((ch) => {
      result = result.replace(ch.char, ch.replacement)
    })

    return result
  }

  const getAttitudeTexts = useCallback(
    (question: IAvaQuestion, positive: boolean) => {
      const searchWords = conversation?.messages.map((cm) => protectString(cm.text))
      const txt = question.answer.text
      const neturalMinAttitudeScore = systemSettings?.sentimentPositiveMin
      const neturalMaxAttitudeScore = systemSettings?.sentimentNegativeMax

      const chunks = findAll({
        searchWords,
        textToHighlight: txt,
      })
      let count = 0
      let firstItemUsed = false

      const textData = chunks.map((chunk) => {
        const { end, highlight, start } = chunk
        const text = txt.substring(start, end)
        const message = conversation.messages.find((m) => m.text === text)
        const active = positive
          ? message?.sentiment_score > neturalMaxAttitudeScore
          : message?.sentiment_score < neturalMinAttitudeScore
        if (highlight && active) {
          count += 1
          const id = firstItemUsed ? '' : 'first-highlighted'
          firstItemUsed = true
          const color = positive
            ? getTabColor(InsightsTab.Positive)
            : getTabColor(InsightsTab.Negative)
          return (
            <>
              <HighLinghtedText id={id} color={color}>
                {text}
              </HighLinghtedText>{' '}
            </>
          )
        }
        return `${text} `
      })
      return {
        text: textData,
        count,
      }
    },
    [conversation, systemSettings]
  )

  const getHesitationTexts = useCallback(
    (question: IAvaQuestion) => {
      const confidentPhrases = systemSettings?.confidentPhrases
        .split('\n')
        .map((w) => `\\b${w.trim()}\\b`)
      const txt = question.answer.text

      const chunks = findAll({
        searchWords: confidentPhrases,
        textToHighlight: txt,
      })
      let count = 0
      let firstItemUsed = false

      const color = getTabColor(InsightsTab.Hesitation)

      const textData = chunks.map((chunk, index) => {
        const { end, highlight, start } = chunk
        const text = txt.substring(start, end)
        const id = `${question.id}|${index}`
        if (highlight) {
          count += 1
          const hId = firstItemUsed ? '' : 'first-highlighted'
          firstItemUsed = true
          return (
            <HighLinghtedTextContainer
              key={id}
              id={hId}
              onMouseMove={() => setActiveText({ questionId: question.id, text, index })}
              onMouseLeave={() => setActiveText(DefActiveText)}
            >
              <HighLinghtedText id={id} color={color}>
                {text} [i]
              </HighLinghtedText>
              <HighLinghtedTextTip
                id={id}
                show={
                  activeText.text === text &&
                  activeText.index === index &&
                  activeText.questionId === question.id &&
                  getPhraseTip(text) !== null
                }
                text={getPhraseTip(text)}
              />
            </HighLinghtedTextContainer>
          )
        }
        return `${text} `
      })
      return {
        text: textData,
        count,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeText, systemSettings?.confidentPhrases]
  )

  const getQuestionHighLightTextData = useCallback(
    (question: IAvaQuestion, tab: InsightsTab) => {
      if (!question) return null
      switch (tab) {
        case InsightsTab.Positive:
          return getAttitudeTexts(question, true)
        case InsightsTab.Negative:
          return getAttitudeTexts(question, false)
        case InsightsTab.Hesitation:
          return getHesitationTexts(question)
        default:
          return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeText, getAttitudeTexts, getHesitationTexts]
  )

  return {
    getInterviewQuestionIndexText,
    getQuestionHighLightTextData,
    getTabColor,
    activeText,
  }
}

export default useComunicationInsights
