import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { TextBold } from 'src/components/Typography'
import { isMobileOnly } from 'react-device-detect'
import arrowDown from '../../static/arrow-down2.svg'

const horizontalPadding = '54px'
const bounce = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }

  100% {
    opacity: 1;
    max-height: 100%;
  }
`
const bounceBack = keyframes`
  0% {
    opacity: 1;
    max-height: 100%;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
`
const height = keyframes`
  0%{
    max-height: 58px;
  }

  100% {
    max-height: 100%;
  }
`

export interface ISessionDetails extends React.HTMLAttributes<HTMLElement> {
  expanded: boolean
  hidden?: boolean
}

export const DataIconContainer = styled.div`
  height: 30px;
  width: 30px;
  padding: 6px;
  border-radius: 15px;
  background-color: #737991;
  margin-right: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const DataIcon = styled.img`
  height: auto;
  max-height: 24px;
  width: 100%;
`

export const DataTitle = styled(TextBold)<{ isLink?: boolean }>`
  font-size: 20px;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'default')};
  color: ${(props) => (props.isLink ? '#007bff' : 'black')};
  &:hover {
    text-decoration: ${(props) => (props.isLink ? 'underline' : 'none')};
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    font-size: 17px;
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }
`

export const DataTitleArrow = styled.span<ISessionDetails>`
  @media screen and (max-width: 600px) {
    position: absolute;
    background-image: url(${arrowDown});
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100%;
    right: 0;
    transform: ${(props) => (props.expanded ? 'rotate(180deg)' : '')};
  }
`

export const DataBody = styled.div<ISessionDetails>`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  display: ${(props) => (props.expanded ? 'flex' : 'none')};
  ${isMobileOnly &&
  css`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    animation: ${bounce} 1s ease normal;
    transition: max-height 1s ease-out;

    ul {
      padding-left: 15px;
    }

    &.hidden {
      animation: ${bounceBack} 0.5s ease normal;
    }
  `}
`

export const DataContainerStyled = styled.div`
  border-radius: 20px;
  padding: 46px ${horizontalPadding};
  background-color: #fff;
  width: 100%;
  @media screen and (max-width: 600px) {
    //max-height: 58px;
    height: auto;
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 15px;
    //animation: ${height} 1s ease normal;
    //transition: max-height 0.15s ease-out;
  }

  @media screen and (max-width: 800px) {
    padding: 23px 27px;
  }
`
