/* eslint-disable no-await-in-loop */
/* eslint-disable no-nested-ternary */
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { ClipboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useStateRef from 'react-usestateref'
import withProps from 'recompose/withProps'
import LoadingIcon from 'src/static/icLoading.png'
import SvgMic from 'src/static/components/SvgMic'
import IcDisclosure from 'src/static/ic-textarea-disclosure.png'
import SvgMicCross from 'src/static/components/SvgMicCross'
import { avaQuestionIndexSelectors } from 'src/redux/data/ava/questionIndex'
import SvgFollowUp from 'src/static/components/SvgFollowUp'
import NoMicImage from 'src/static/icNoMic.png'
import {
  avaInterviewSelectors,
  avaReplaceQuestionAction,
  avaSaveAnswerAction,
  avaSettingsSelectors,
  startAvaInterviewFollowUpWatcherAction,
  systemSettingsSelectors,
} from 'src/redux'
import SvgReplaceQuestion from 'src/static/components/SvgReplaceQuestion'
import useBranding from 'src/utils/useBranding'
import useSpeechToText, { STTProvider } from 'src/ava/services/useSpeechToText'
import * as interviewSelectors from 'src/interview/selectors'
import {
  AnimatedBase,
  BaseDisable,
  ButtonBase,
  ErrorContainer,
  NavButton,
  QuestionCounterContainer,
  TitleText,
} from '../elements/styled-components'
import BottomComponent from '../intro/BottomComponent'
import ErrorPopup, { ErrorType } from '../ErrorPopup'
import useAvaSound from '../../services/avaSound'
import ChangeQuestionPopup from './ChangeQuestionPopup'
import ListeningAnimation from './ListeningAnimation'
import LiveText from '../LiveText'
import AnimatedButton from './AnimatedButton'
import BallsAnimation from './BallsAnimation'
import MultilineLiveText from '../MultilineLiveText'
import LoadingBar from '../elements/LoadingBar'

const AnimationFirstSpeedMs = 166
const AnimationLastSpeedMs = 2000

const Container = styled.div<{ nonFullHeight?: boolean }>`
  width: 100%;
  flex: 1;
  padding: 0 64px;
  display: flex;
  flex-direction: column;

  ${isMobileOnly &&
  css`
    background: white;
    padding: 32px 16px 0 16px;
    border-radius: 24px 24px 0px 0px;
  `}
`

const LineContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  min-height: 0;
`

const ButtonsContainer = styled(BaseDisable)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;
  margin-top: 42px;
  ${isMobileOnly &&
  css`
    margin-top: 17px;
    margin-bottom: 16px;
  `}
`

const TextAreaContainer = styled(BaseDisable)<{ collapsed: boolean }>`
  background: white;
  position: relative;
  padding: 24px 23px 0 32px;
  border-radius: 28px;
  height: ${({ collapsed }) => (!isMobileOnly ? (collapsed ? '69px' : '232px') : '100%')};
  max-height: ${({ collapsed }) =>
    isMobileOnly ? (collapsed ? '69px' : '400px') : 'unset'};
  flex: ${isMobileOnly ? '1' : 'unset'};
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 25px 5px rgb(0 0 0 / 7%);
`

const SpeechText = styled.div`
  font-weight: 400;
  line-height: 150%;
  color: black;
  font-size: 14px;
  width: 100%;
  overflow-y: auto;
  white-space: break-spaces;
  margin-bottom: ${isMobileOnly ? 10 : 40}px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }
`

const DynamicText = styled.span`
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  opacity: 0.5;
  font-size: 14px;
`

const DynamicPlaceholder = styled.div`
  font-weight: 400;
  line-height: 150%;
  color: gray;
  font-size: 14px;
  width: 100%;
`

const TextArea = styled.textarea<{ disabled?: boolean; collapsed: boolean }>`
  background: transparent;
  border: none;
  margin-right: ${({ collapsed }) => (collapsed ? 290 : 0)}px;
  margin-bottom: ${({ collapsed }) => (collapsed ? 18 : isMobileOnly ? 10 : 40)}px;
  overflow: ${({ collapsed }) => (collapsed ? 'hidden' : 'visible')};
  flex: 1;
  width: 100%;
  font-weight: 400;
  line-height: 150%;
  color: black;
  outline: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  font-size: 14px;
  resize: none;
  padding: 0;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }
`

const StaticTextArea = styled.div<{ empty?: boolean }>`
  background: transparent;
  margin-right: 290px;
  margin-bottom: 18px;
  flex: 1;
  width: 100%;
  font-weight: 400;
  line-height: 150%;
  color: black;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: ${({ empty }) => (empty ? 0.5 : 1)};

  ${isMobileOnly &&
  css`
    margin-right: 0;
  `}
`

const CharCounterLabel = styled.div<{ collapsed: boolean }>`
  position: absolute;
  bottom: ${({ collapsed }) => (collapsed ? 25 : 18)}px;
  right: 56px;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  width: fit-content;
  align-self: flex-end;
  color: #000000;
  opacity: 0.5;

  ${isMobileOnly &&
  css`
    position: unset;
    margin-top: 4px;
    font-size: 10px;
    line-height: 15px;
  `}
`

const MicButton = styled.div`
  height: 100%;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;

  > span {
    flex: 1;
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    padding: 0 24px;
    align-items: center;
    display: flex;
    font-size: 14px;
  `}
`

const DisclosureButton = styled.img<{ collapsed: boolean }>`
  width: 25px;
  height: 25px;
  padding: 0 7px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 13px;
  transform: rotate(${({ collapsed }) => (collapsed ? 180 : 0)}deg);
  transition: transform 0.5s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  &:active {
    background: rgba(0, 0, 0, 0.1);
  }
`

const AnimatedContainer = styled.div`
  position: relative;
  width: 100%;
  animation: fadeInFromNone 0.5s ease-out;
  margin-bottom: ${isMobileOnly ? 0 : 72}px;
  display: flex;
  flex-direction: column;
  flex: 1;

  &:placeholder {
    opacity: 0.8;
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

const ReplaceQuestionContainer = styled.div`
  display: flex;
`

const ReplaceQuestionButton = withProps()(styled(ButtonBase)`
  position: relative;
  color: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin-left: 8px;

  ${isMobileOnly &&
  css`
    width: fit-content;
  `}

  &:hover {
    text-decoration: underline;
  }
`)

const BottomWrapper = styled.div`
  margin-top: auto;
`

const AnimationContainer = styled.div`
  display: flex;
  margin-top: -6px;
  height: fit-content;
  align-items: center;
  flex: 1;
`

const AnimationText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  margin-left: 18px;
`

const TitleTextStyled = styled(TitleText)`
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 8px;

  ${isMobileOnly &&
  css`
    font-size: 20px;
    line-height: 28px;
  `}
`

const PreTitleText = styled(BaseDisable)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #000;

  ${isMobileOnly &&
  css`
    font-size: 14px;
    line-height: 20px;
  `}
`

const InfoPopup = styled(AnimatedBase)`
  width: fit-content;
  position: absolute;
  color: #ff1f44;
  padding: 12px;
  background: #3e3452;
  border-radius: 8px;
  display: flex;
  align-items: center;
  max-width: 340px;

  top: 0;
  left: 196px;

  ${isMobileOnly &&
  css`
    position: fixed;
    top: unset;
    top: 10px;
    left: 16px;
    z-index: 10;
    width: calc(100% - 32px);
    max-width: unset;
    height: fit-content;
  `}
`

const InfoPopupHeader = styled.div`
  color: #fff;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: ${isMobileOnly ? 8 : 16}px;
`

const InfoPopupText = styled.div`
  color: #fff;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  flex: 1;
  white-space: break-spaces;

  > ol {
    margin: 0;
    padding-left: 14px;
    margin-bottom: 16px;
  }
`

const InfoPopupButton = styled(ButtonBase)<{ toRight?: boolean }>`
  color: #fff;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 18px;
  align-self: ${({ toRight }) => (toRight && !isMobileOnly ? 'end' : 'unset')};
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
`

const GoButton = styled(NavButton)`
  height: 55px;
  margin-top: auto;
  margin-bottom: ${isMobileOnly ? '16px' : '8px'};
  width: 100%;
`

const LoadingImg = styled.img`
  width: 24px;
  height: 24px;
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 72px);
  left: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 72px;
  border-radius: 24px 24px 0px 0px;

  ${isMobileOnly &&
  `
    padding: 0 20px;
  `}
`

const LoadingText = styled.div`
  color: #000;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 32px;

  ${isMobileOnly &&
  `
    text-align: center;
    font-size: 20px;
  `}
`

const PermissionPopup = styled(AnimatedBase)`
  width: fit-content;
  position: absolute;
  color: #ff1f44;
  padding: 12px;
  background: #3e3452;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 280px;

  top: 73px;
  left: 0;

  &:after {
    display: ${isMobileOnly ? 'none' : 'block'};
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #3e3452;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -20px;
    left: 20px;
  }

  ${isMobileOnly &&
  css`
    position: fixed;
    top: unset;
    top: 10px;
    left: 16px;
    z-index: 10;
    width: calc(100% - 32px);
    max-width: unset;
    height: fit-content;
    flex-direction: row;
    align-items: center;
  `}
`

const PopupLineContainer = styled.div`
  flex: 1;
`

const NoMicImg = styled.img`
  width: 12px;
  height: 12px;
  margin: ${isMobileOnly ? '0 6px 0 0' : '0 2px'};
  object-fit: contain;
  margin-bottom: 2px;
`

enum PopupState {
  NoShown,
  Show,
  Shown,
}

interface IProps {
  goNext: () => void
  goBack: () => void
  canGoBack: boolean
}

const LoadItems = ['Analyzing your answer...', 'Generating follow-up question']

const MinCharCount = 250

const AIQuestionComponent = ({ goNext, goBack, canGoBack }: IProps) => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()

  const { playSound } = useAvaSound()
  const { agencyColor } = useBranding()
  const questionIndex = useSelector(avaQuestionIndexSelectors.data)
  const avaSettings = useSelector(avaSettingsSelectors.data)
  const interview = useSelector(avaInterviewSelectors.data)
  const agency = useSelector(interviewSelectors.agency)
  const systemSettings = useSelector(systemSettingsSelectors.data)
  // const loading = useSelector(avaSaveAnswerSelectors.isLoading)
  const [loading, setLoading] = useState(false)

  const [textValue, setTextValue, textValueRef] = useStateRef<string>()
  const [infoPopupState, setInfoPopupState] = useState(PopupState.NoShown)
  const [permissionPopupState, setPermissionPopupState] = useState(PopupState.NoShown)

  const [dynamicText, setDynamicText, dynamicTextRef] = useStateRef('')
  const [isRecording, setIsRecording, isRecordingRef] = useStateRef(false)
  const [showInput, setShowInput] = useState(false)
  const [error, setError] = useState<ErrorType>(null)
  const [textError, setTextError] = useState(false)
  const [collapsed, setCollapsed] = useState(true)
  const [showChangeQuestionPopup, setShowChangeQuestionPopup] = useState(
    PopupState.NoShown
  )
  const [progress, setProgress, progressRef] = useStateRef(0)
  const [loadingText, setLoadingText] = useState(LoadItems[0])
  const [animationSpeedMs, setAnimationSpeedMs] = useState(0)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const speechTextRef = useRef<HTMLDivElement>(null)
  const useTouch = useRef(false)
  const textCache = useRef<string[]>([])
  const questionRef = useRef(undefined)

  const questions = interview?.questions.map((q) => [q, ...(q.follow_up || [])]).flat()
  const question = questions.find((q) => q.id === questionIndex.questionId)
  const isFollowUp = !!question?.parent_id

  const getInterviewQuestionIndexText = () => {
    if (!question) return ''
    const currentQuestionIndex = questions.findIndex((q) => q.id === question.id) + 1

    const normalizeNumber = (num: number) => {
      return `${num < 10 ? '0' : ''}${num}`
    }

    return `${normalizeNumber(currentQuestionIndex)}/${normalizeNumber(
      questions.length
    )} - ${question.question_type} Question${question.parent_id ? '- follow up' : ''}`
  }

  const onRecognition = (newStringValue: string, isFinal: boolean) => {
    if (!isRecordingRef.current) return

    if (isFinal) {
      if (textCache.current.length > 0) {
        textCache.current.shift()
        setTextValue((t) => `${t} ${newStringValue}`)
      } else {
        setDynamicText('')
        setTextValue((t) => `${t} ${newStringValue}`)
      }
    } else if (dynamicTextRef.current.length - newStringValue.length >= 20) {
      if (newStringValue.length > 5) {
        textCache.current.push(dynamicTextRef.current)
        console.log(
          '[onSpeechToText][setTextCache]',
          dynamicTextRef.current,
          newStringValue
        )
        setDynamicText(newStringValue)
      } else {
        console.log('[onSpeechToText][ignore]', newStringValue)
      }
    } else {
      setDynamicText(newStringValue)
    }
    if (speechTextRef.current) {
      speechTextRef.current.scrollTop = speechTextRef.current.scrollHeight
    }
  }

  const onDisconnected = useCallback(() => {
    if (textCache.current.length > 0) {
      setTextValue((t) => `${t}${textCache.current.map((tc) => tc)}`)
      textCache.current = []
    }
    if (dynamicText) {
      setTextValue((t) => `${t} ${dynamicText}`)
    }
    setDynamicText('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicText])

  const {
    isConnected,
    isReady: providerIsReady,
    changeMicDevice,
    muteSpeechToText,
    runSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    interviewId: interview.id,
    provider: systemSettings.sttProvider as STTProvider,
    onRecognition,
    onDisconnected,
  })

  const runProgress = async () => {
    while (progressRef.current < 0.91) {
      await new Promise((resolve) => setTimeout(resolve, AnimationFirstSpeedMs))
      setProgress((v) => v + 0.01)
    }
    while (progressRef.current < 0.99) {
      await new Promise((resolve) => setTimeout(resolve, AnimationLastSpeedMs))
      setProgress((v) => v + 0.01)
    }
  }

  const onNext = useCallback(() => {
    if (infoPopupState === PopupState.Show) {
      setInfoPopupState(PopupState.Shown)
    }
    if (permissionPopupState === PopupState.Show) {
      setPermissionPopupState(PopupState.NoShown)
    }
    if (textCache.current.length > 0) {
      setTextValue((t) => `${t}${textCache.current.map((tc) => tc)}`)
      textCache.current = []
    }
    if (dynamicText) {
      setTextValue((t) => `${t} ${dynamicText}`)
    }
    setIsRecording(false)
    if (textValueRef.current.length < MinCharCount) {
      setTextError(true)
    } else {
      dispatch(
        avaSaveAnswerAction({
          interviewToken,
          questionId: question.id,
          answer: textValueRef.current,
        })
      )

      if (question.order !== 1) goNext()
      else {
        setLoading(true)
        dispatch(startAvaInterviewFollowUpWatcherAction({ interviewToken }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, textValue, infoPopupState])

  const handleReplaceQuestion = () => {
    dispatch(
      avaReplaceQuestionAction({
        interviewToken,
        questionId: question.id,
      })
    )

    setShowInput(false)
    setShowChangeQuestionPopup(PopupState.Shown)
    // setIsRecording(false)
    setTextValue('')
    setDynamicText('')
    textCache.current = []
  }

  const handleReplaceQuestionButton = () => {
    if (showChangeQuestionPopup === PopupState.Shown) {
      handleReplaceQuestion()
    } else {
      setShowChangeQuestionPopup(PopupState.Show)
    }
  }

  const getRecordingButtonIcon = useCallback(() => {
    if (isRecording) {
      return isConnected ? <SvgMicCross fill="white" /> : <LoadingImg src={LoadingIcon} />
    }
    return <SvgMic fill="white" />
  }, [isConnected, isRecording])

  const getRecordingButtonText = useCallback(() => {
    if (isRecording) {
      return isConnected ? 'Done' : 'Connecting...'
    }
    return 'Answer'
  }, [isConnected, isRecording])

  const runPermissionWatcher = () => {
    const timer = setInterval(() => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop()
          })
          clearInterval(timer)
          setPermissionPopupState(PopupState.NoShown)
        })
        .catch(() => {
          // do nothing
        })
    }, 1000)
  }

  const handleRecordingClicked = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop()
        })
        setIsRecording((v) => !v)
        setCollapsed(false)
      })
      .catch(() => {
        setPermissionPopupState(PopupState.Show)
        runPermissionWatcher()
      })
    // setIsRecording((v) => !v)
  }

  const handleCopyPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    if (agency?.disable_copy_paste_answer) {
      e.preventDefault()
      alert('Copying and pasting is not allowed!')
    }
  }

  useEffect(() => {
    if (question && questionRef.current?.text !== question.text) {
      if (question.order !== 1) {
        if (progress !== 0) {
          setProgress(1)
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        } else {
          setLoading(false)
        }
      }
      setIsRecording(false)
      setShowInput(false)
      setTextError(false)
      setCollapsed(true)
      setTextValue(question.answer?.text || '')
      setDynamicText('')
      textCache.current = []
    }
    questionRef.current = question

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question])

  useEffect(() => {
    if (isRecording) {
      playSound('/static/sound/ava-start-recording.wav')
      muteSpeechToText(false)
    } else if (showInput) {
      if (textCache.current.length > 0) {
        setTextValue((t) => `${t}${textCache.current.map((tc) => tc)}`)
        textCache.current = []
      }
      if (dynamicText) {
        setTextValue((t) => `${t} ${dynamicText}`)
      }
      if (infoPopupState !== PopupState.Shown && textValueRef.current) {
        setInfoPopupState(PopupState.Show)
      }
      setDynamicText('')
      playSound('/static/sound/ava-stop-recording.wav')
      muteSpeechToText(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording])

  useEffect(() => {
    if (showInput) {
      playSound('/static/sound/ava-go-input.wav')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInput])

  useEffect(() => {
    if (providerIsReady)
      runSpeechToText()
        .then(() => muteSpeechToText(true))
        .catch((err: DOMException) => {
          if (err.message.toLowerCase().includes('permission denied')) {
            setError(ErrorType.PermissionDenied)
          }
          setIsRecording(false)
        })
    return () => stopSpeechToText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerIsReady])

  useEffect(() => {
    if (avaSettings.microphoneId) {
      changeMicDevice(avaSettings.microphoneId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaSettings.microphoneId])

  useEffect(() => {
    setTextError(false)
  }, [textValue, dynamicText])

  useEffect(() => {
    if (!collapsed && textAreaRef.current) {
      textAreaRef.current.focus()
      textAreaRef.current.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length
      )
    }
  }, [collapsed])

  useEffect(() => {
    window.onscroll = () => {
      if (useTouch.current) {
        ;(document.activeElement as HTMLElement).blur()
      }
    }
  }, [])

  useEffect(() => {
    if (loading) {
      runProgress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (progress > 1) setProgress(1)
    if (progress < 0.54) {
      setLoadingText(LoadItems[0])
    } else {
      setLoadingText(LoadItems[1])
    }

    if (progress < 0.91 || progress === 1) {
      setAnimationSpeedMs(AnimationFirstSpeedMs)
    } else {
      setAnimationSpeedMs(AnimationLastSpeedMs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  const dynamicStringPart = `${textCache.current.map((tc) => tc)}${
    dynamicText ? ` ${dynamicText}` : ''
  }`

  const actualString = `${textValue}${dynamicStringPart}`

  const Placeholder = isRecording
    ? 'Speak naturally, and I’ll transcribe your answer'
    : 'Type your answer here'

  return (
    <>
      <Container
        onTouchStart={() => {
          useTouch.current = true
        }}
        onTouchEnd={() => {
          useTouch.current = false
        }}
      >
        <QuestionCounterContainer>
          {isFollowUp && <SvgFollowUp fill="#A9A9A9" />}
          {getInterviewQuestionIndexText()}
        </QuestionCounterContainer>

        {!isFollowUp && (
          <TitleTextStyled disabled={loading}>
            <LiveText text={question?.text} onFinish={() => setShowInput(true)} />
          </TitleTextStyled>
        )}
        {isFollowUp && (
          <MultilineLiveText
            text={[question?.intro, question?.text].join('')}
            lines={[question?.intro, question?.text]}
            render={(lines) => (
              <>
                {lines[0] && <PreTitleText disabled={loading}>{lines[0]}</PreTitleText>}
                {lines[1] && (
                  <TitleTextStyled disabled={loading}>{lines[1]}</TitleTextStyled>
                )}
              </>
            )}
            onFinish={() => setShowInput(true)}
          />
        )}

        {showInput && (
          <AnimatedContainer>
            <TextAreaContainer collapsed={collapsed} disabled={loading}>
              <LineContainer>
                {isRecording && collapsed && (
                  <AnimationContainer>
                    <ListeningAnimation />
                    <AnimationText>Transcribing answer...</AnimationText>
                  </AnimationContainer>
                )}
                {isRecording && !collapsed && (
                  <>
                    {!textValue && !dynamicStringPart && (
                      <DynamicPlaceholder>
                        Start talking and see your words appear here
                      </DynamicPlaceholder>
                    )}
                    {(textValue || dynamicStringPart) && (
                      <SpeechText ref={speechTextRef}>
                        {textValue}
                        <DynamicText>{dynamicStringPart}</DynamicText>
                      </SpeechText>
                    )}
                  </>
                )}
                {!collapsed && !isRecording && (
                  <TextArea
                    maxLength={2500}
                    ref={textAreaRef}
                    collapsed={collapsed}
                    rows={isMobileOnly ? 2 : 4}
                    disabled={isRecording}
                    value={actualString}
                    placeholder={Placeholder}
                    onChange={(ev) => {
                      setTextValue(ev.target.value)
                      setCollapsed(false)
                    }}
                    onCopy={handleCopyPaste}
                    onCut={handleCopyPaste}
                    onPaste={handleCopyPaste}
                  />
                )}
                {!isRecording && collapsed && (
                  <StaticTextArea
                    empty={actualString === ''}
                    onClick={() => setCollapsed(false)}
                  >
                    {actualString !== '' ? actualString : Placeholder}
                  </StaticTextArea>
                )}
                <DisclosureButton
                  collapsed={collapsed}
                  src={IcDisclosure}
                  alt="disclosure"
                  onClick={() => setCollapsed((v) => !v)}
                />
              </LineContainer>
              {!isMobileOnly && (
                <CharCounterLabel collapsed={collapsed}>
                  {actualString.length}/{MinCharCount} (You must have minimum of{' '}
                  {MinCharCount} chars)
                </CharCounterLabel>
              )}
            </TextAreaContainer>
            {isMobileOnly && (
              <CharCounterLabel collapsed={collapsed}>
                {actualString.length}/{MinCharCount} (You must have minimum of{' '}
                {MinCharCount} chars)
              </CharCounterLabel>
            )}
            <ButtonsContainer disabled={loading}>
              <AnimatedButton
                onClick={handleRecordingClicked}
                animate={isRecording && isConnected}
                disabled={loading || (isRecording && !isConnected)}
              >
                <MicButton>
                  {getRecordingButtonIcon()}
                  <span>{getRecordingButtonText()}</span>
                </MicButton>
              </AnimatedButton>
              {permissionPopupState === PopupState.Show && (
                <>
                  {isMobileOnly && (
                    <PermissionPopup>
                      <PopupLineContainer>
                        <InfoPopupHeader>
                          <NoMicImg src={NoMicImage} />
                          Your microphone is blocked
                        </InfoPopupHeader>

                        <InfoPopupText>
                          To record your answer, grant Wizco permission to use your
                          microphone
                        </InfoPopupText>
                      </PopupLineContainer>
                      <InfoPopupButton
                        toRight
                        onClick={() => setPermissionPopupState(PopupState.Shown)}
                      >
                        Got it
                      </InfoPopupButton>
                    </PermissionPopup>
                  )}
                  {!isMobileOnly && (
                    <PermissionPopup>
                      <InfoPopupHeader>Your microphone is blocked</InfoPopupHeader>
                      <InfoPopupText>
                        <ol>
                          <li>
                            Click the &apos;microphone blocked&apos;
                            <NoMicImg src={NoMicImage} />
                            icon in your browser&apos;s address bar.
                          </li>
                          <li>
                            Grant Wizco permission to use your microphone and refresh the
                            page.
                          </li>
                        </ol>
                      </InfoPopupText>
                      <InfoPopupButton
                        toRight
                        onClick={() => setPermissionPopupState(PopupState.Shown)}
                      >
                        Got it
                      </InfoPopupButton>
                    </PermissionPopup>
                  )}
                </>
              )}
              {infoPopupState === PopupState.Show && (
                <InfoPopup>
                  <InfoPopupText>
                    You can edit your response by typing or recording.
                  </InfoPopupText>
                  <InfoPopupButton onClick={() => setInfoPopupState(PopupState.Shown)}>
                    Got it
                  </InfoPopupButton>
                </InfoPopup>
              )}
              <ReplaceQuestionContainer>
                <SvgReplaceQuestion fill={agencyColor} />
                <ReplaceQuestionButton
                  disabled={loading}
                  onClick={handleReplaceQuestionButton}
                >
                  Replace Question
                </ReplaceQuestionButton>
              </ReplaceQuestionContainer>
            </ButtonsContainer>
            {!isMobileOnly && (
              <BottomWrapper>
                <BottomComponent
                  onNext={onNext}
                  showBackButton={canGoBack}
                  onBack={goBack}
                  loading={question?.order === 1 && loading}
                  loadingText="Analyzing your answer"
                />
              </BottomWrapper>
            )}
            {isMobileOnly && (
              <GoButton onClick={onNext} hideIcon>
                {loading ? <BallsAnimation /> : 'Next'}
              </GoButton>
            )}
            {textError && (
              <ErrorContainer maxWidth={isMobileOnly ? null : 296} right={0} bottom={69}>
                {isMobileOnly
                  ? `Minimum response: ${MinCharCount} characters`
                  : `To get valuable insights, your response should have a minimum of ${MinCharCount} characters`}
              </ErrorContainer>
            )}
          </AnimatedContainer>
        )}
      </Container>
      {error && <ErrorPopup errorType={error} onClose={() => setError(null)} />}
      {showChangeQuestionPopup === PopupState.Show && (
        <ChangeQuestionPopup
          onClose={() => setShowChangeQuestionPopup(PopupState.Shown)}
          onChange={handleReplaceQuestion}
        />
      )}
      {loading && (
        <LoadingContainer>
          <LoadingBar progress={progress} animationSpeedMs={animationSpeedMs} />
          <LoadingText>{loadingText}</LoadingText>
        </LoadingContainer>
      )}
    </>
  )
}

export default AIQuestionComponent
