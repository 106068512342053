import { call, delay, put, race, take } from 'typed-redux-saga'

import { AvaInterviewProgress, AvaInterviewStatus, avaInterviewStatus } from '../../api'
import { reduxWatcherFactory } from '../../utils/redux'
import { updateAvaInterviewAction } from '../data/ava/interview'
import { avaGetInterviewAction } from '../requests/ava/getInterview'

interface IAvaInterviewWatcherAction {
  interviewToken: string
}

const {
  startAction: startAvaInterviewWatcherAction,
  startActionType: startAvaInterviewWatcherActionType,
  stopAction: stopAvaInterviewWatcherAction,
  stopActionType: stopAvaInterviewWatcherActionType,
} = reduxWatcherFactory<IAvaInterviewWatcherAction>('avaInterview')

function* avaInterviewWatcher(interviewToken) {
  while (true) {
    try {
      const { interview } = yield* call(avaInterviewStatus, interviewToken)

      if (
        interview.status === AvaInterviewStatus.IN_INTERVIEW &&
        interview?.interview_progress >= AvaInterviewProgress.QUESTIONS_GENERATED
      ) {
        yield put(avaGetInterviewAction({ interviewToken }))
        yield put(stopAvaInterviewWatcherAction)
      } else {
        yield put(updateAvaInterviewAction(interview))
        yield call(delay, 1000)
      }
    } catch (err) {
      yield put(stopAvaInterviewWatcherAction)
    }
  }
}

function* watchAvaInterviewWatcher() {
  while (true) {
    const action = yield take(startAvaInterviewWatcherActionType)
    yield race([
      call(avaInterviewWatcher, action.payload.interviewToken),
      take(stopAvaInterviewWatcherActionType),
    ])
  }
}

export {
  startAvaInterviewWatcherAction,
  startAvaInterviewWatcherActionType,
  stopAvaInterviewWatcherAction,
  stopAvaInterviewWatcherActionType,
  watchAvaInterviewWatcher,
}
