import React, { useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useAvaSound from 'src/ava/services/avaSound'
import { AnimatedBase, NavButton, TitleText } from '../elements/styled-components'
import LiveText from '../LiveText'

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  flex: 1;
  padding: 94px 200px 0 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;

  @media screen and (max-width: 1100px) {
    padding: 40px;
  }

  ${isMobileOnly &&
  css`
    flex: 1;
    flex-direction: column;
    margin: 0;
    padding: 42px 16px 16px !important;
    background: white;
    border-radius: 20px 20px 0 0;
  `}
`

const InfoText = styled.div`
  font-weight: 600;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #21304e;
  margin-top: 9px;
  text-align: center;

  ${isMobileOnly &&
  css`
    width: 100%;
    text-align: start;
  `}
`

const Separator = styled(AnimatedBase)`
  width: 510px;
  height: 0px;
  border: 0.5px solid #000000;
  margin: 42px 0;
`

const LineContainer = styled.div`
  display: flex;
  ${isMobileOnly &&
  css`
    margin-top: auto;
    width: 100%;
  `}
`

const GoButton = styled(NavButton)`
  height: 55px;

  ${isMobileOnly &&
  css`
    margin: 24px 0 0 0;
    width: 100%;
  `}
`

interface IProps {
  title?: string
  info?: string
  backMode?: boolean
  goNext: () => void
}

const InfoComponent = ({ backMode, title, info, goNext }: IProps) => {
  const { playSound } = useAvaSound()
  const [showDetails, setShowDetails] = useState(false)
  const [showButton, setShowButton] = useState(false)

  const onGoNext = () => {
    playSound('/static/sound/ava-run-interview.wav')
    goNext()
  }

  const ButtonText = "Let's Go!"

  return (
    <Container>
      <TitleText textAlign={isMobileOnly ? 'start' : 'center'}>
        <LiveText
          staticText={backMode}
          text={title}
          onFinish={() => setShowDetails(true)}
        />
      </TitleText>
      {showDetails && (
        <InfoText>
          <LiveText
            staticText={backMode}
            text={info}
            onFinish={() => setShowButton(true)}
          />
        </InfoText>
      )}
      {showButton && (
        <>
          {!isMobileOnly && <Separator />}
          <LineContainer>
            <GoButton onClick={onGoNext} hideIcon>
              {ButtonText}
            </GoButton>
          </LineContainer>
        </>
      )}
    </Container>
  )
}

InfoComponent.defaultProps = {
  title: '',
  info: '',
  backMode: false,
}

export default InfoComponent
