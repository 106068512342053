/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'
import { avaInterviewSelectors } from 'src/redux'
import mIcon from 'src/static/report_terminology.svg'
import withProps from 'recompose/withProps'
import SvgShare from 'src/static/components/SvgShare'
import useBranding from 'src/utils/useBranding'
import BlockIcon from './BlockIcon'

const Container = styled.div``

const TerminologyContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 20px;
`

const ReadingContainer = withProps()(styled.div`
  padding: 35px 18px 20px 18px;
  margin: 0 -18px;
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  margin-bottom: 24px;
`)

const HeaderText = styled.div<{ color: string }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ color }) => color};
`

const TerminologyItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 17px;
  border: 1px solid #e1e3ea;
  border-radius: 8px;
`

const ReadingItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`

const TerminologyItemStyled = styled.div<{ last?: boolean }>`
  border-bottom: ${({ last }) => (last ? '0' : '1')}px solid #e1e3ea;
  padding: 12px 0;
  margin: 0 16px;
`

const RecommendationTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #222b45;
  display: flex;
  align-items: center;
  flex: 1;
`

const TerminologyTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #222b45;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

const TerminologyText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #222b45;

  transition: max-height 0.3s linear;
  overflow: hidden;
`

const RecommendationItem = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 15px;
  height: 72px;
`

const LineContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const ProTipsContainerMobile = () => {
  const interview = useSelector(avaInterviewSelectors.data)
  const { agencyColor } = useBranding()

  if (!interview) return undefined

  const goTo = (url: string) => {
    let website = url
    if (!url.startsWith('http')) {
      website = `https://${website}`
    }
    window.open(website, '_blank', 'noreferrer')
  }

  return (
    <Container>
      <TerminologyContainer>
        <LineContainer>
          <BlockIcon icon={mIcon} />
          <HeaderText color="#3B424F">{`${interview.position.position} common terminology`}</HeaderText>
        </LineContainer>
        <TerminologyItemsContainer>
          {interview.position.position_terminologies.map((item, index) => (
            <TerminologyItem
              key={index}
              name={item.name}
              description={item.description}
              last={index === interview.position.position_terminologies.length - 1}
            />
          ))}
        </TerminologyItemsContainer>
      </TerminologyContainer>

      <ReadingContainer>
        <HeaderText color="#fff">Recommended resources</HeaderText>
        <ReadingItemsContainer>
          {interview.position.position_readings.map((item, index) => (
            <RecommendationItem key={index} onClick={() => goTo(item.link)}>
              <RecommendationTitle>{item.title}</RecommendationTitle>
              <SvgShare fill={agencyColor} />
            </RecommendationItem>
          ))}
        </ReadingItemsContainer>
      </ReadingContainer>
    </Container>
  )
}

const TerminologyItem = ({
  name,
  description,
  last,
}: {
  name: string
  description: string
  last: boolean
}) => {
  return (
    <TerminologyItemStyled last={last}>
      <TerminologyTitle>{name}</TerminologyTitle>
      <TerminologyText>{description}</TerminologyText>
    </TerminologyItemStyled>
  )
}

export default ProTipsContainerMobile
