import { reduxDataFactory } from '../../../utils/redux'

export interface IAvaQuestionIndex {
  questionCount: number
  questionNumber: number
  questionNumberText: string
  questionId: string
}

const defaultValues: IAvaQuestionIndex = {
  questionCount: 0,
  questionNumber: 0,
  questionNumberText: '',
  questionId: undefined,
}

const {
  action: updateAvaQuestionsIndex,
  actionType: updateAvaQuestionIndexType,
  reducer: avaQuestionIndexReducer,
  selectors: avaQuestionIndexSelectors,
} = reduxDataFactory<IAvaQuestionIndex>('questionIndex', 'ava', defaultValues)

export {
  updateAvaQuestionsIndex,
  updateAvaQuestionIndexType,
  avaQuestionIndexReducer,
  avaQuestionIndexSelectors,
}
