import styled from '@emotion/styled'
import SvgDiscloserSmall from 'src/static/components/SvgDiscloserSmall'
import React, { useEffect, useState } from 'react'

import { IAvaQuestion } from 'src/api'
import useComunicationInsights, {
  InsightsTab,
} from 'src/ava/services/useCommunicationInsights'
import SvgLamp from 'src/static/components/SvgLamp'
import useBranding from 'src/utils/useBranding'
import { CheckedLabel } from '../elements/styled-components'
import CircleProgressSmall from './CircleProgressSmall'

const QuestionDataContainer = styled.div<{ visible: boolean; usePadding: boolean }>`
  padding: ${({ usePadding }) => (usePadding ? 32 : 0)}px;
  flex: 1;
  flex-direction: column;
  max-height: ${({ visible }) => (visible ? '1000px' : '0px')};
  overflow: auto;
  transition: max-height 0.5s;
`

const DetailsTitle = styled.div`
  color: #222b45;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  height: auto;
`

const LineContainer = styled.div<{ useCursor?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const TabsContainer = styled.div`
  display: flex;
  margin: 12px 0 0;

  @media (max-width: 920px) {
    margin: 12px 0 0;
  }
`

const AnswerContainer = styled.div<{ collapsed?: boolean }>`
  padding: 16px;
  max-height: ${({ collapsed }) => (collapsed ? 48 : 280)}px;
  border-radius: 8px;
  background: #f5f6f8;
  margin-top: 24px;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const DiscoloserContainer = styled.div<{ collapsed?: boolean }>`
  display: flex;
  transform: rotate(${({ collapsed }) => (collapsed ? 0 : 180)}deg);
  transition: transform 0.2s;
`

const AnswerTitle = styled.div`
  color: #222b45;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const AnswerText = styled.div`
  color: #222b45;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
  overflow-x: auto;
`

const DescTitle = styled.div`
  color: #222b45;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
`

const DescText = styled.div`
  color: #222b45;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: auto;
  overflow-x: auto;
`

const Separator = styled.div`
  width: 100%;
  height: 0;
  border-top: 1px solid #e1e3ea;
  margin: 16px 0 24px 0;
`

const getTransparentColor = (color: string) => {
  return `${color}1E`
}

const HighlightedTextInfoContainer = styled.div<{ color: string; empty?: boolean }>`
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid ${({ empty }) => (empty ? '#e3e5ea' : 'transparent')};
  background: ${({ empty, color }) => (empty ? '#fff' : getTransparentColor(color))};
  display: flex;
  align-items: center;
  align-self: end;
  margin-top: 24px;
  width: 100%;
  max-width: 100%;
`

const HighlightedTextInfo = styled.div<{ color: string; empty?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ color, empty }) => (empty ? '#222B45' : color)};
  margin-left: ${({ empty }) => (empty ? '8' : '0')}px;
  flex: 1;
`

const TabsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1440px) {
    width: 100%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: start;
  }
`

const Tabs = [InsightsTab.Positive, InsightsTab.Negative, InsightsTab.Hesitation]

interface IProps {
  question: IAvaQuestion
  visible: boolean
  usePadding: boolean
}

const QuestionDetails = ({ question, visible, usePadding }: IProps) => {
  const { getQuestionHighLightTextData, getTabColor } = useComunicationInsights()
  const { agencyColor } = useBranding()

  const [answerCollapsed, setAnswerCollapsed] = useState(false)
  const [highlightedText, setHighlightedText] = useState(null)
  const [noHighlightedText, setNoHighlightedText] = useState(false)
  const [highlightedTextInfo, sethighlightedTextInfo] = useState('')
  const [activeTab, setActiveTab] = useState(InsightsTab.Positive)

  useEffect(() => {
    if (question) {
      setActiveTab(InsightsTab.Positive)
      setAnswerCollapsed(false)
    }
  }, [question])

  useEffect(() => {
    const textData = getQuestionHighLightTextData(question, activeTab)
    if (!textData) return
    setHighlightedText(textData.text)
    if (textData.count > 0) {
      setNoHighlightedText(false)
      switch (activeTab) {
        case InsightsTab.Positive:
          sethighlightedTextInfo(
            `${textData.count} positive sentiment${
              textData.count > 1 ? 's' : ''
            } highlighted.`
          )
          break
        case InsightsTab.Negative:
          sethighlightedTextInfo(
            `${textData.count} negative sentiment${
              textData.count > 1 ? 's' : ''
            } highlighted.`
          )
          break
        case InsightsTab.Hesitation:
          sethighlightedTextInfo(
            `${textData.count} moment${
              textData.count > 1 ? 's' : ''
            } of hesitation observed.`
          )
          break
        default:
          sethighlightedTextInfo('')
      }
    } else {
      setNoHighlightedText(true)
      switch (activeTab) {
        case InsightsTab.Positive:
          sethighlightedTextInfo(
            `No positive sentiment observed. Being more positive will amplify your impact.`
          )
          break
        case InsightsTab.Negative:
          sethighlightedTextInfo(
            `No negative sentiment observed. Navigating without any negativity is an art, and you've mastered it!`
          )
          break
        case InsightsTab.Hesitation:
          sethighlightedTextInfo(
            'No hesitation words or phrases observed. Straightforward and assertive - your message is free of doubts!'
          )
          break
        default:
          sethighlightedTextInfo('')
      }
    }
  }, [question, activeTab, getQuestionHighLightTextData])

  return (
    <QuestionDataContainer
      visible={visible}
      usePadding={usePadding}
      onClick={(e) => e.stopPropagation()}
    >
      {!usePadding && <Separator />}
      <TopContainer>
        <LineContainer>
          <TabsBlock>
            <DetailsTitle>Highlight your communication tone:</DetailsTitle>
            <TabsContainer>
              {Tabs.map((tab) => (
                <CheckedLabel
                  key={tab}
                  checked={tab === activeTab}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </CheckedLabel>
              ))}
            </TabsContainer>
          </TabsBlock>
          <CircleProgressSmall
            value={question?.score ?? 1}
            maxValue={10}
            useHint
            hintText="Your score assessment"
          />
        </LineContainer>
        <HighlightedTextInfoContainer
          empty={noHighlightedText}
          color={getTabColor(activeTab)}
        >
          {noHighlightedText && <SvgLamp fill={agencyColor} />}
          <HighlightedTextInfo empty={noHighlightedText} color={getTabColor(activeTab)}>
            {highlightedTextInfo}
          </HighlightedTextInfo>
        </HighlightedTextInfoContainer>
      </TopContainer>
      <AnswerContainer collapsed={answerCollapsed}>
        <LineContainer useCursor onClick={() => setAnswerCollapsed((v) => !v)}>
          <AnswerTitle>your answer</AnswerTitle>
          <DiscoloserContainer collapsed={answerCollapsed}>
            <SvgDiscloserSmall fill="#222B45" />
          </DiscoloserContainer>
        </LineContainer>
        <AnswerText>{highlightedText}</AnswerText>
      </AnswerContainer>
      <DescTitle>Key takeaways</DescTitle>
      <DescText>{question?.recommendations}</DescText>
    </QuestionDataContainer>
  )
}

export default QuestionDetails
