import styled from '@emotion/styled'
import React, { useRef } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  DataIconContainer,
  DataIcon,
  DataTitle,
  DataBody,
} from '../../../report/components/SharedStyledComponents'

const DataHeader = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 10px;
    margin: -10px;
  }
`

export const DataContainerStyled = styled.div`
  position: relative;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  margin-bottom: 57px;
  padding: 46px 54px;
  background-color: #fff;
  width: 100%;

  @media screen and (max-width: 600px) {
    height: auto;
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1280px) {
    padding: 16px 18px;
  }

  ${isMobileOnly &&
  css`
    box-shadow: none;
    padding: 0 !important;
    margin-bottom: 0 !important;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DataTitleStyled = styled(DataTitle)`
  line-height: 28px;
`

const SubTitle = styled.div`
  color: #3b424f;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export default function AvaReportBlockContainer({
  icon,
  title,
  subTitle,
  children,
  isTitleLink,
  onTitleClick,
}: {
  icon: any
  title?: string
  subTitle?: string
  children: any
  isTitleLink?: boolean
  onTitleClick?: (ev?: any) => void
}) {
  const dataRef = useRef(null)

  return (
    <DataContainerStyled>
      {(title || subTitle) && (
        <DataHeader>
          {icon && (
            <DataIconContainer>
              <DataIcon src={icon} />
            </DataIconContainer>
          )}
          <TitleContainer>
            {title && (
              <DataTitleStyled
                onClick={(ev) => {
                  if (isTitleLink) {
                    onTitleClick(ev)
                  }
                }}
                isLink={isTitleLink}
              >
                {title}
              </DataTitleStyled>
            )}
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </TitleContainer>
        </DataHeader>
      )}
      <DataBody expanded ref={dataRef}>
        {children}
      </DataBody>
    </DataContainerStyled>
  )
}

AvaReportBlockContainer.defaultProps = {
  isTitleLink: false,
  title: '',
  subTitle: '',
  onTitleClick: () => {},
}
