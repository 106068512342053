import { call, delay, put, race, take } from 'typed-redux-saga'

import store from 'src/redux/store'
import { avaGetInterview } from '../../api'
import { reduxWatcherFactory } from '../../utils/redux'
import {
  avaGetInterviewAction,
  avaGetInterviewActionSuccessType,
} from '../requests/ava/getInterview'
import { updateAvaQuestionsIndex } from '../data/ava/questionIndex'

interface IAvaInterviewFollowUpWatcherAction {
  interviewToken: string
}

const {
  startAction: startAvaInterviewFollowUpWatcherAction,
  startActionType: startAvaInterviewFollowUpWatcherActionType,
  stopAction: stopAvaInterviewFollowUpWatcherAction,
  stopActionType: stopAvaInterviewFollowUpWatcherActionType,
} = reduxWatcherFactory<IAvaInterviewFollowUpWatcherAction>('avaInterviewFollowUp')

function* avaInterviewFollowUpWatcher(interviewToken) {
  while (true) {
    try {
      const { interview } = yield* call(avaGetInterview, interviewToken)

      const questions = interview?.questions
        .map((q) => [q, ...(q.follow_up || [])])
        .flat()

      const firstQuestion = questions.find((q) => q.order === 1)
      const firstAnswerPresent = firstQuestion.answer?.is_valid !== undefined
      const firstAnswerValid = !!firstQuestion.answer?.is_valid
      const followUp = questions.find((q) => !!q.parent_id)

      if (firstAnswerPresent && (!firstAnswerValid || !!followUp)) {
        yield put(avaGetInterviewAction({ interviewToken }))
        yield take(avaGetInterviewActionSuccessType)
        const state = store.getState()
        yield put(
          updateAvaQuestionsIndex({
            ...state.ava.questionIndex.data,
            questionId: followUp ? followUp.id : questions.find((q) => q.order === 2)?.id,
          })
        )

        yield put(stopAvaInterviewFollowUpWatcherAction)
      } else {
        yield call(delay, 1000)
      }
    } catch (err) {
      yield put(stopAvaInterviewFollowUpWatcherAction)
    }
  }
}

function* watchAvaInterviewFollowUpWatcher() {
  while (true) {
    const action = yield take(startAvaInterviewFollowUpWatcherActionType)
    yield race([
      call(avaInterviewFollowUpWatcher, action.payload.interviewToken),
      take(stopAvaInterviewFollowUpWatcherActionType),
    ])
  }
}

export {
  startAvaInterviewFollowUpWatcherAction,
  startAvaInterviewFollowUpWatcherActionType,
  stopAvaInterviewFollowUpWatcherAction,
  stopAvaInterviewFollowUpWatcherActionType,
  watchAvaInterviewFollowUpWatcher,
}
