/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useStateRef from 'react-usestateref'
import { TitleText } from '../elements/styled-components'
import LiveText from '../LiveText'
import { SpeechTexts } from '../../speechService'
import { avaInterviewSelectors, startAvaInterviewWatcherAction } from '../../../redux'
import { AvaInterviewProgress } from '../../../api'
import LoadingBar from '../elements/LoadingBar'

const AnimationFirstSpeedMs = 500
const AnimationLastSpeedMs = 2000

const Container = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 94px auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${isMobileOnly &&
  css`
    flex: 1;
    justify-content: start;
    flex-direction: column;
    margin: 0;
    padding: 42px 16px;
    background: white;
    border-radius: 20px 20px 0 0;
  `}
`

const LoadingText = styled.div<{ disabled?: boolean }>`
  color: rgba(115, 121, 145, 0.6);
  font-family: Rubik;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
`

const SubTitleText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000;
  margin-bottom: 136px;
`

const LoadItems = [
  'Analyzing your information',
  'Generating potential questions',
  'Ensuring accuracy and relevance',
]

interface IProps {
  backMode: boolean
  goNext: () => void
}

const GettingQuestionsComponent = ({ backMode, goNext }: IProps) => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()

  const interview = useSelector(avaInterviewSelectors.data)
  const [titleIsReady, setTitleIsReady] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [loadingText, setLoadingText] = useState(LoadItems[0])
  const [animationSpeedMs, setAnimationSpeedMs] = useState(0)
  const [progress, setProgress, progressRef] = useStateRef(0)

  const runProgress = async () => {
    while (progressRef.current < 0.91) {
      await new Promise((resolve) => setTimeout(resolve, AnimationFirstSpeedMs))
      setProgress((v) => v + 0.01)
    }
    while (progressRef.current < 0.99) {
      await new Promise((resolve) => setTimeout(resolve, AnimationLastSpeedMs))
      setProgress((v) => v + 0.01)
    }
  }

  useEffect(() => {
    if (showDetails) {
      runProgress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetails])

  useEffect(() => {
    if (progress > 1) setProgress(1)
    if (progress < 0.31) {
      setLoadingText(LoadItems[0])
    } else if (progress < 0.72) {
      setLoadingText(LoadItems[1])
    } else {
      setLoadingText(LoadItems[2])
    }

    if (progress < 0.91 || progress === 1) {
      setAnimationSpeedMs(AnimationFirstSpeedMs)
    } else {
      setAnimationSpeedMs(AnimationLastSpeedMs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  useEffect(() => {
    if (interview?.interview_progress === AvaInterviewProgress.QUESTIONS_GENERATED) {
      if (progress > 0) {
        setProgress(1)
        setTimeout(() => {
          goNext()
        }, 1000)
      } else {
        goNext()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview])

  useEffect(() => {
    dispatch(startAvaInterviewWatcherAction({ interviewToken }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  return (
    <Container>
      <TitleText textAlign="center">
        <LiveText
          staticText={backMode}
          text={SpeechTexts.GettingQuestions}
          onFinish={() => setTitleIsReady(true)}
        />
      </TitleText>
      {titleIsReady && (
        <SubTitleText>
          <LiveText
            staticText={backMode}
            text={SpeechTexts.IfYouDontLikeQuestion}
            onFinish={() => setShowDetails(true)}
          />
        </SubTitleText>
      )}
      {showDetails && (
        <>
          <LoadingBar progress={progress} animationSpeedMs={animationSpeedMs} />
          <LoadingText>{loadingText}</LoadingText>
        </>
      )}
    </Container>
  )
}

export default GettingQuestionsComponent
